@import 'src/styles/variables';
.body {
  padding: 16px;
  padding-bottom: 24px;
}
.header {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  color: var(--fontInvertedColor);

  text-align: center;
}

.containerButtons {
  display: flex;
  width: 100%;
}

.button {
  border-top: 1px solid #b4b4b4;
  width: 100%;
  min-height: 56px;
  justify-content: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontInvertedColor);
}
