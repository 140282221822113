@import "src/styles/variables";

.header {
    padding-top: 24px;
  
    font-family: $bodyFontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  
    color: var(--fontInvertedColor);
  
    text-align: center;
  }
  
  .containerButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  .button {
    border-top: 1px solid #b4b4b4;
    width: 100%;
    min-height: 56px;
    justify-content: center;
  
    font-family: $bodyFontFamily;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */
  
    display: flex;
    align-items: center;
    text-align: center;
  
    color: var(--fontInvertedColor);
  }
  