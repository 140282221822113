@import 'src/styles/variables.scss';

.menucardBadge {
  margin-right: 4px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;

  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: var(--fontColor);

  padding: $dp * 0.125 $dp * 0.5;
  text-align: center;
  font-size: 11px;
  height: 24px;

  background: var(--viewBgLighten);
  // &:first-child {
  //   border-top-left-radius: calc(var(--borderRadius) / 6);
  // }

  &:last-child {
    margin-right: 0;
  }
}

.largeTag {
  width: 100%;
}
