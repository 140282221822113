@import 'src/styles/variables';

.wrapper {
  z-index: 50;
  position: fixed;
  top: 0;
}

.sbutton {
  font-size: 20px;
  line-height: 25px;
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: block;
  width: 100%;
  text-align: center;
  max-width: 290px;
  margin: $dp * 4 auto $dp * 4 auto;
  padding: 12px 0 12px 0;
}

.wrapperScan {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 200;
  width: 100vw;
  background: #f6f6f6;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: #000;
}

/* Old styles --------------------- */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-size: 17px;
  font-weight: 400;
  background: none;
  color: #000;
  z-index: 999;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.formInput {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 8px;
}

.formInput::placeholder {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: initial;

  color: #8d8d8d;
}

.navbar .icon {
  width: 24px;
}

.manual {
  margin: $dp $dp 0 $dp;
}

.manual input {
  text-align: center;
  width: 100%;
  padding: 0 10px;
  background: #ededed;
  // color: #fff;
  border: 1px solid #666;
  border-radius: 10px;
  height: 36px;
}

.manual input:active {
  background-color: #fff;
  color: #000;
}

.manual input:focus {
  background-color: #fff;
  color: #000;
}

.reader {
  width: 100%;
}

.reader section div:first-child {
  box-shadow: none !important;
  border: #353434 45px solid !important;
  border-radius: 22%;
}

.tableVec {
  width: calc(100vw - 120px);
  height: calc(100vw - 120px);
  stroke: var(--accentColor);
}

.readerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  z-index: 200;
  width: 100vw;
  height: 100vw;
  top: 0px;
  // background: url('../../assets/icons/qr_reader.svg');
  // background-size: calc(100% - 120px);
  // background-position: center;
  // background-repeat: no-repeat;
}

.help {
  text-align: center;
  color: #5c5c5c;
  font-size: 10px;
}

.help svg {
  padding: 10px;
}

.tableNum {
  font-family: $bodyFontFamily;
  font-size: 140px;
  line-height: 176px;
  padding: 15px 0px;
}

.table {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 80px);
  background: #ffffff;
  border-radius: 15px;
}

.tableWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeIcon {
  position: absolute;
  left: 10px;
}

.doneWrapper {
  padding-top: $dp * 4;
  padding-bottom: $dp * 4;
  margin-bottom: $dp * 4;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.done {
  width: calc(100vw - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--accentColor);
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.doneText {
  padding: 10px 0px;
}

.error {
  font-weight: 700;
  color: red;
}

.title {
  font-family: $bodyFontFamily;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.subtitle {
  font-family: $bodyFontFamily;
  font-size: 14px;
  line-height: 18px;
}

.hideScrollbar {
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100vw;
  z-index: 100;
  margin: 0 auto;
  overflow: hidden;
}
.hideScrollbar:after {
  content: '';
  position: absolute;
  left: 40px;
  top: 40px;
  border-radius: 30px;
  width: calc(100vw - 80px);
  height: calc(100vw - 80px);
  box-shadow: 0px 0px 0px 2000px #000000;
}

.hideScrollbar::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.errorWrapper {
  position: absolute;
  top: 48px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentWrapper {
  margin-top: 32px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  width: calc(100vw - 80px);
  background-color: #ffffff;
  border: 1px solid #696969;
  height: 40px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.textarea {
  min-height: 102px;
  width: calc(100vw - 70px);
  margin: 0px 12px;
}

.telegramText {
  margin-left: 12px;
}

.item:nth-child(1) {
  margin-top: 0px;
}

.paymentText {
  width: calc(100vw - 80px);
  text-align: center;
}

.unpay {
  background: #dadada;
}

.mask {
  position: absolute;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 10px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.5);
}

.confirmationUi {
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationBox {
  margin: 0px 24px;
  max-width: 300px;
  width: 100%;
  min-height: 288px;

  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--accentColor);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.confirmationBox1 {
  // padding-top: 48px;
  margin: 0px 16px 0px 16px;
  // width: 100%;
  // min-height: 494px;

  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--accentColor);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmationBoxHeader {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 160% */

  text-align: center;

  color: var(--fontInvertedColor);
}

.confirmationBoxButtonWrapperInrapper {
  display: flex;
  width: 100%;
}

.confirmationBoxButtonInrapper {
  flex: 1;
  width: 100%;
  border-top: #b4b4b4;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationBoxButtonInrapper:first-child {
  border-right: 1px solid #b4b4b4;
}

.tableFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  // line-height: 45px;
  text-align: center;
  text-transform: capitalize;

  color: var(--fontInvertedColor);
}

.tableFont1 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 47px;
  line-height: 59px;
  text-transform: capitalize;

  color: var(--fontInvertedColor);
}

.tableFont2 {
  margin: 16px 16px 0px 16px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;

  color: var(--fontInvertedColor);
}

.tableFont3_1 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  color: var(--fontInvertedColor);
}

.tableFont3 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 68px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;

  color: var(--fontInvertedColor);
}

.tableFont4 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: var(--fontInvertedColor);
}

.hr {
  height: 1px;
  background-color: transparent;
  width: 100%;
}

.confirmationBoxButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.vr {
  min-width: 1px;
  background-color: #b4b4b4;
  height: 56px;
}

.confirmationBoxButton {
  border-top: 1px solid #b4b4b4;
  width: 100%;
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontInvertedColor);
}

.smollText {
  margin-top: 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontInvertedColor);

  opacity: 0.5;
}

.bigText {
  margin-top: 4px;
  margin-bottom: 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontInvertedColor);
}

.confirmationBottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.confirmationBottomStatic {
  width: 100%;
}

@media only screen and (max-width: 372px) and (max-height: 600px) {
  .confirmationBox1 {
    margin: 0px 16px 100px 16px;
    // border-radius: 0px;
    // max-width: none;
    // height: 100vh;
    // width: 100vw;
    // overflow: hidden;
    // overflow-y: scroll;
  }
  // .confirmationBottom {
  //   position: relative;
  //   bottom: 0px;
  //   width: 100%;
  //   padding-bottom: 80px;
  // }
}

.thankText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--fontInvertedColor);
  margin-top: 8px;
}

.imHerePopupBody {
  max-width: calc(100vw - 50px);
}

.imHerePopup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boldbold {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;

  color: var(--fontInvertedColor);
}

.codeText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: var(--fontInvertedColor);

  text-align: left;
}

.stolHeader {
  padding-top: 24px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  color: var(--fontInvertedColor);

  text-align: center;
}

.stopDesc {
  margin-top: 16px;
  margin-bottom: 16px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: var(--fontInvertedColor);

  text-align: center;
}

.stolSelectionNoIcon {
  fill: var(--fontInvertedColor);
}
.deliveryIcon {
  fill: var(--fontInvertedColor);
}
.togoIcon {
  fill: var(--fontInvertedColor);
}
.tableIcon {
  fill: var(--fontInvertedColor);
}
.stolSelectionIcon {
  fill: var(--fontInvertedColor);
}

@media (min-width: 1025px) {
  .imHerePopupBody {
    width: 320px;
    max-width: 320px;
  }
  .readerWrapper {
    position: absolute;
    width: 400px;
    height: 400px;
    top: 0px;
  }
  .tableVec {
    width: 400px;
    height: 400px;
    stroke: var(--accentColor);
  }
  .hideScrollbar {
    position: absolute;
    top: 0px;
    width: 400px;
    height: 400px;
    z-index: 100;
    margin: 0 auto;
    overflow: hidden;
  }
  .hideScrollbar:after {
    content: '';
    position: absolute;
    left: 40px;
    top: 40px;
    border-radius: 30px;
    width: calc(400px - 80px);
    height: calc(400px - 80px);
  }
}

.textwrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
}
