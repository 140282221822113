.wrapper {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 130px);
  margin: 0 auto;
  position: relative;
  min-height: 112px;
  button:disabled {
    background: #c4c4c4 !important;
  }
}

.callStuffButton,
.paymentMethodButtonsWrapper button {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  font-size: 20px;
  line-height: 24px;
  background-color: transparent;
}

.callStuffButtonActive,
.paymentMethodButtonsWrapper button {
  background-color: var(--accentColor);
  border: none;
}

.callStuffButton {
  transition: 0.4s background-color ease;
  color: var(--fontColor);
  margin-bottom: 16px;
  border: 1px solid var(--fontColor);
}

.callStuffButtonActive {
  color: var(--fontInvertedColor);
  border-color: transparent;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
}

.paymentMethodButtonsWrapper {
  position: absolute;
  top: 64px;
  z-index: 1;

  button {
    transition: 0.3s all ease;
    color: var(--fontInvertedColor);
    &:nth-child(2),
    &:nth-child(3) {
      opacity: 0;
      visibility: hidden;
    }
  }

  &Inactive {
    button {
      &:first-child {
        color: var(--fontColor);
        background-color: transparent;
        border: 1px solid var(--fontColor);
      }
    }
  }

  &Active {
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
    button {
      &:nth-child(1) {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: var(--accentColorDarken);
      }

      &:nth-child(2) {
        border-top: 1px solid var(--viewBg);
        border-bottom: 1px solid var(--viewBg);
        border-radius: 0px;
      }

      &:nth-child(3) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
