@import '../../styles/variables';

.face1 {
  background-image: url('../../assets/emoji/a1.png');
}

.face2 {
  background-image: url('../../assets/emoji/a3.png');
}

.face3 {
  background-image: url('../../assets/emoji/a4.png');
}

.face4 {
  background-image: url('../../assets/emoji/a5.png');
}

.face5 {
  background-image: url('../../assets/emoji/a6.png');
}

.face1,
.face2,
.face3,
.face4,
.face5 {
  mix-blend-mode: luminosity;
  opacity: 0.5;
  transition: all 0.4s ease;
  /* width: 40px;
  height: 40px; */
  /* background-size: 40px; */
  background-position: center;
  background-size: contain;
}

.confirmation {
  position: fixed;
  top: 0px;
  z-index: 200;
  width: 100vw;
  height: calc(100vh);
  background: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: #000;
}

.title {
  margin-top: 12px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.closeIcon {
  position: absolute;
  left: 10px;

  width: 36px;
  height: 48px;
  background-size: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/back.svg');
}
.settingsWrapper {
  position: absolute;
  bottom: 0px;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsImg {
  width: calc(100vh - 40px);
  height: 170px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--brandingBackgroundImage);
}

.textarea {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin: 0px 24px;
  min-height: 155px;
  width: calc(100vw - 48px);
  padding: 8px;
}

.tableFont2 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  color: #000000;
}

.checkControl {
  padding: 20px 24px 0px 0px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.checkControl svg rect {
  fill: var(--accentColor);
}

.check {
  background: rgba(0, 0, 0, 0.54);
  mix-blend-mode: normal;
}

.checkLabel {
  margin-left: 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
}

.wrapper {
}

.list {
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(100vw - 80px);
  background-color: #ffffff;
  background: #ffffff;
  border-radius: 6px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  color: #455a64;
}

.item svg:first-of-type {
  margin-left: 32px;
  position: absolute;
}

.telegramText {
  margin-left: 12px;
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}

.item:nth-child(1) {
  margin-top: 18px;
}

.paymentText {
  width: calc(100vw - 80px);
  text-align: center;
}

.paymentWrapper {
  margin-top: 32px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faces {
  height: 104px;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  margin-bottom: 54px;
}

.face {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.face div:nth-child(1) {
  width: 40px;
  height: 40px;
}

.faceText {
  position: absolute;

  top: 64px;

  text-align: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #000000;
}

.face .faceText {
  display: none;
}

.selectedFace .faceText {
  display: flex;
}

.selectedFace div:nth-child(1) {
  width: 56px;
  height: 56px;
  opacity: 1;
  mix-blend-mode: normal;
  /* font-size: 44px; */
}

.commentUnButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: gray;
  border-radius: 6px;

  width: 93px;
  height: 32px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: (--fontColor);
}

.commentButon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--accentColor);
  border-radius: 6px;
  width: 93px;
  height: 32px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--fontInvertedColor);
}

.newBodytext {
  text-align: left;
  padding: 0px 24px 0px 24px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
}

.bodyText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-size: 16px;
  /* margin-left: 16px; */
  line-height: 32px;
  /* or 178% */

  display: flex;
  align-items: center;

  color: var(--fontColor);
}

.someGuards {
  padding: 8px 16px;
}

@media (min-width: 1025px) {
  .textarea {
    width: 360px;
  }
}
