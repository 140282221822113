.title {
  margin-top: 12px;
}

.closeIcon {
  position: absolute;
  left: 10px;

  width: 26px;
  height: 26px;
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/close_black.svg');
}
.settingsWrapper {
  position: absolute;
  bottom: 0px;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  padding-top: 52px;
}

.openEmpty {
  padding: 16px;
  margin: 32px;
  border-radius: 16px;
  background: #000;
  color: #fff;
}

// .skidka {
//   width: 128px;
//   height: 128px;
//   background-image: url('../../assets/qrcode.svg');
//   background-size: 128px;
//   background-position: center;
//   background-repeat: no-repeat;
// }
