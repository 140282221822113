@import 'src/styles/variables';

.confirmation {
  position: fixed;
  top: 0px;
  z-index: 200;
  width: 100vw;
  height: calc(100vh);
  background: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: #000;
}
.settingsWrapper {
  position: absolute;
  bottom: 0px;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsImg {
  width: calc(100vh - 40px);
  height: 170px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--brandingBackgroundImage);
}

.wrapper {
  padding: 16px;
  padding-top: 52px;
}

.terms {
  z-index: 2;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;

  color: var(--fontColor);

  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
  opacity: 0.5;
}
.terms a {
  text-decoration: underline;
  color: var(--fontColor);
}
.wrapper p {
  /* font-size: 1.1em; */
  /* line-height: 1.5; */
  text-align: left;
  text-indent: 32px;
}
