@import 'src/styles/variables';

.endStock {
  color: #979797;
}

.wrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: var(--viewBg);
  z-index: 2;
  /* min-height: 100%; */
  overflow-y: scroll;
  padding-bottom: 44px;
  /* font-family: 'Stratos'; */
}

.bgDishWrapper {
  padding-bottom: 100px;
}

ul {
  margin: 0 0;
}

.image {
  width: 100%;
  position: relative;
}

// .react-tabs__tab--selected {
//   border-bottom: 2px solid #010101;
//   padding-bottom: 8px;
//   margin-bottom: -1px;
// }

.dish_image {
  width: 100vw;
  height: 100vw;
  background-size: cover;
  background-position: center;
}

.time {
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  padding: 0px 8px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time2 {
  margin: 0px 57px;
  display: flex;
  justify-content: space-between;
}

.badgeRight {
  position: absolute;
  bottom: 0;
  right: 16px;
}
.none {
  opacity: 0;
}

.badgeLeft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  background-color: #fff;
  z-index: 3;
  /* background: #f9d953; */
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  /* transition: all 0.6s; */
}

.sticked {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  background-color: #f9d953;
  z-index: 3;
  /* background: #f9d953; */
  // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: none;
  transition: all 0.6s;
}

.sticked .subtitle {
  transition: all 1s;
  height: 0;
  overflow: hidden;
}

.title__wrap {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  align-items: flex-start;
}

.title {
  margin: 0;
  margin-bottom: 7px;
  font-size: 1.5em;
  font-weight: bold;
}

.subtitle {
  margin: 0;
  font-size: 0.875em;
  line-height: 18px;
  font-weight: 300;
  max-width: 80%;
}

.price {
  white-space: nowrap;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;
}

.react-tabs {
}

.react-tabs__list {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  padding: 0px 25px 0px 25px;

  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  margin: 0 0 17px;
}

.react-tabs__title {
  padding-bottom: 7px;
  margin-right: 40px;
  font-size: 0.875em;
}

.react-tabs__tab--selected {
  color: white !important ;
  border-bottom: 2px solid #ffcc3a;
}

.tab-wrap {
  height: 100%;
}

.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.tab-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}

// .tab-content:last-child {
//   padding: 0 8px 55px;

//   @media (min-width: 1025px) {
//     padding: 0 16px 220px;
//   }
// }

// .tab-content {
//   padding: 0 8px 0px;
//   position: relative;
// }

.strokeSpacer {
  // stroke: #ffffff;
}
.strokeOne {
  stroke: #9585ff;
}
.strokeTwo {
  stroke: #ff9081;
}
.strokeTri {
  stroke: #74e086;
}

.tabFlex {
  display: flex;
  align-items: center;
}

.tabFlex2 {
  display: flex;
}

.tabContent {
  padding: 0 16px 0px;
  position: relative;
}

.reset {
  font-size: 0.625em;
  color: #c4c4c4;
}

.react-tabs__tab--selected {
  color: black;
  background: transparent;
  border-bottom: 2px solid #fc125f;
}

.form_group {
  margin-top: $dp * 1.5;
}

.form_group:first-child {
  margin-top: 16px;
}

.formGroup {
  margin-top: $dp * 1.5;
}

.option__title {
  /* font-size: 0.625em; */
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */
}

.optionTitle {
  /* font-size: 0.625em; */
  margin-bottom: 11px;
  color: var(--fontColor);
}

.form-check {
  margin-top: $dp;
}

.formCheck {
  margin-top: $dp;
}

.form-check-input {
  display: none;
}

.formCheckInput {
  display: none;
}

.form-check-label,
.form-check-label-disabled,
.form-check-label-disabled-check,
.form-check-label-checked-dis,
.form-check-label-checked {
  position: relative;
  padding-left: 33px;
}

.formCheckLabel,
.formCheckLabelChecked {
  position: relative;
  padding-left: 33px;
}

.form-check-label-disabled-check:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;

  box-shadow: 0 0 1px #8d8d8d;
  background: #c4c4c4;
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.form-check-label-checked-dis:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  box-shadow: 0 0 1px #8d8d8d;
  background: var(--warningColor);
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.form-check-label-checked:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;

  box-shadow: 0 0 1px #8d8d8d;
  background: var(--accentColor);
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.formCheckLabelChecked:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;

  box-shadow: 0 0 1px #8d8d8d;
  background: var(--accentColor);
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.form-check-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  box-shadow: 0 0 1px #8d8d8d;
  background: var(--accentColor);
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.form-check-label-disabled:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  box-shadow: 0 0 1px #8d8d8d;
  background: #c4c4c4;
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.formCheckLabel:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  box-shadow: 0 0 1px #8d8d8d;
  background: #ffed6c;
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.form-check-label-checked:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 7px;
  border-radius: 50%;
  background: var(--fontInvertedColor);
  height: 8px;
  width: 8px;
  transition: all ease 0.9s;
}

.form-check-label-checked-dis:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 7px;
  border-radius: 50%;
  background: var(--fontInvertedColor);
  height: 8px;
  width: 8px;
  transition: all ease 0.9s;
}

.form-check-label-disabled-check:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 7px;
  border-radius: 50%;
  background: var(--fontInvertedColor);
  height: 8px;
  width: 8px;
  transition: all ease 0.9s;
}

.formCheckLabelChecked:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 7px;
  border-radius: 50%;
  background: var(--fontInvertedColor);
  height: 8px;
  width: 8px;
  transition: all ease 0.9s;
}

.makro_title {
  margin-bottom: 24px;
  margin-top: 16px;
}

.makro_mass {
  border-bottom: 5px solid #ececec;
  padding-bottom: 5px;
}

.optionLabel {
  color: var(--fontColor);
  display: flex;
  width: 100%;
}

.sizeOptionLabel {
  flex-direction: column;
}

.makro_list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.makro_sublist {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.makro_item,
.makro_subitem {
  display: flex;
  justify-content: space-between;
  padding: 9px 0;
  border-bottom: 1px solid #c4c4c4;
  font-weight: lighter;
}

.makro_subitem {
  padding-left: 35px;
}

.makro_item span {
  font-weight: bold;
}

.makro_subitem span {
  font-weight: 600;
}

.add_block {
  display: flex;
  align-items: center;

  background: var(--moneyColor);
  border-radius: 41px;
  min-height: 56px;

  position: absolute;
  top: 100%;
  right: 16px;
  text-align: center;
  color: #fff;
}

.add_text {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  min-width: 40px;
}

.add_button {
  border: none;
  background: var(--moneyColor);
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
}

.rem_button {
  background-color: transparent;
  border: none;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  outline: none;
  color: #fff;
}

.warn_button {
  background-color: var(--warningColor);
}

.closed_button {
  background-color: #dadada;
}

.disabled_button {
  background-color: #cdcdcd;
}

.add_button img,
.edit_button img {
  margin: auto;
}

.change_amount_block {
  margin-top: 54px;
  margin-bottom: 50px;
  text-align: center;
}

.change_amount_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.amount {
  font-size: 50px;
  margin: 5px 35px;
}

.change_amount_text {
  font-size: 0.625em;
  margin-bottom: 15px;
}

.change_amount_button {
  outline: none;
  border: none;
  background: transparent;
  border: 1px solid #c4c4c4;
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50%;
  margin: 0;
}

.change_amount_button img {
  margin: auto;
}

.adding {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 200;
  transition: opacity 0.3s, visibility 0.3s;
}

.add {
  opacity: 1;
  visibility: visible;
}

.addingText {
  background-color: #5dc56c;
  width: 70vw;
  height: 70vw;
  font-size: 3rem;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}

.editText {
  background-color: #23629f;
  width: 70vw;
  height: 70vw;
  font-size: 3rem;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}

.edit_block {
  position: absolute;
  right: 16px;
  text-align: center;
  color: #23629f;
}

.edit_text {
  font-size: 0.625em;
  margin-bottom: 15px;
}

.edit_button {
  border: none;
  background: #23629f;
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50%;
  margin: 0 auto;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.noadd {
  opacity: 0;
  visibility: hidden;
}

/* .dish_arrow {
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-image: url('../../assets/icons/dish-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
} */

.dishPrice {
  color: var(--fontInvertedColor);
  &:not(:first-child) {
    margin-left: 4px;
  }

  text-transform: lowercase;
}

// #F6D6DD
.rightSection2 {
  padding-top: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.leftItem {
  width: 24px;
}

.warnStock {
  background-color: var(--warningColor);
  color: #fff;
  padding: 0px 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
}

.menuClosed {
  background-color: #dadada;
}

.dishHeader {
  background: var(--mainBg);
  height: 48px;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  display: flex;
  align-items: center;
}

.dishdesc {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: var(--fontColor);
  padding: 24px;
  padding-top: 8px;
}

.dishdesc p,
.dishdesc ul li {
  list-style-position: inside;
  color: var(--fontColor);
}

.dishdesc {
  h1 {
    font-family: $bodyFontFamily;
    font-size: 34px;
    line-height: 43px;
    /* identical to box height */
    color: var(--fontColor);
    opacity: 1;
  }
  h2 {
    font-family: $bodyFontFamily;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */

    color: var(--fontColor);
    opacity: 1;
  }
  h3 {
    font-family: $bodyFontFamily;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */

    color: var(--fontColor);
    opacity: 1;
  }
  h4 {
    font-family: $bodyFontFamily;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    color: var(--fontColor);
    opacity: 1;
  }
}

.caloryChart {
  width: calc(50vw - 28px);
}

.caloryList {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.caloryItem {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.caloryName {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: var(--fontCaptionColor);
}

.caloryValue {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  &::after {
    font-weight: normal;
    color: var(--fontCaptionColor);
  }
}
.caloryText {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.caloryPoint {
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.proteinPoint {
  background-color: #9585ff;
}

.fatPoint {
  background-color: #ff9081;
}

.carbsPoint {
  background-color: #74e086;
}

.diaText {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 12vw;
  line-height: 150%;
}

.ccal {
  margin-top: -8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  text-align: center;

  color: #979797;
}

.dishNav {
  width: 100%;
  height: 48px;
  position: fixed;
  z-index: 15;
  background-color: var(--viewBg);
  .navbarIconWrapper32 {
    position: absolute;
  }

  @media (min-width: 1025px) {
    width: 396px;
  }
}

.dishNotNav {
  background-color: transparent;
}

.dishNav2 {
  background-color: var(--mainBg);
  position: relative;
  min-height: 48px;
  width: 100%;
}

@media (min-width: 1025px) {
  .wrap::-webkit-scrollbar {
    display: none;
  }
  .wrap {
    position: absolute;
  }
  .bgDishWrapper {
    padding-bottom: 0px;
  }
  .dish_image {
    width: 420px;
    height: 420px;
    background-position: center;
  }
  .caloryList {
    display: flex;
    flex-direction: column;
    max-width: calc(420px - 164px - 48px);
  }
  .diaText {
    position: absolute;
    font-size: 48px;
  }
}

.dishTitle {
  max-height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  line-height: 28px;
}

.rightSectionScrolled {
  div {
    background: rgba(0, 0, 0, 0.05);
  }
}

.sizesWrapper {
  display: flex;
  margin-top: 16px;
  padding-left: 32px;
}

.sizeWrapper {
  text-transform: capitalize;
  &:not(:last-child) {
    margin-right: 26px;
  }
}

.preorder {
  background: var(--attentionBgColor);
  color: #ffffff;
  padding: 3px 8px;
}

.optionNameWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.optionModifierData {
  color: var(--fontCaptionColor);
  display: flex;
  align-items: center;
}

.optionPrice {
  position: relative;
  margin-left: 8px !important;
}

.dishUnits {
  color: var(--fontCaptionColor);
}
