@import 'variables';

body {
  font-family: $bodyFontFamily;
  --namelaka-bg: rgba(255, 255, 255, 0.59);
  --namelaka-bg-darken: rgba(255, 255, 255, 0.8);
}

.markerWrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.bgWrapper {
  position: relative;
  margin-top: auto;
  min-height: 200px;
}

.bgIcon {
  margin-top: 10px;
  width: calc(100vw - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  & img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &_pattern {
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -80px;
      left: calc(50% - 50vw);
      min-height: 80px;
      width: 100vw;
      background: var(--brandPattern);
      background-repeat: repeat-y;
      background-size: auto;
      background-position: top center;
    }
  }
}

.dishCardPattern {
  margin-top: auto;
}

.bgSign {
  width: 17.57px;
  height: 17.57px;
  margin-top: 8px;
  -webkit-mask-image: url('../assets/poi-hlbn.svg');
  mask-image: url('../assets/poi-hlbn.svg');
  background-color: #ffffff;
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

.markerBase {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 34.35px;
  height: 44.17px;
  path {
    fill: #fff;
  }
}

.active_marker > svg > path {
  fill: $markerActiveColor;
}
.default_marker > svg > path {
  fill: var(--accentColor);
}
.disabled_marker > svg > path {
  fill: var(--marker-disabled, #7e7e7e);
}

.active_marker .bgSign {
  background-color: var(--fontColor);
}

.marker_small,
.marker_small .markerBase {
  width: 18.66px;
  height: 24px;
}
.marker_normal,
.marker_normal .markerBase {
  width: 34.35px;
  height: 44.17px;
}
.marker_big,
.marker_big .markerBase {
  width: 62px;
  height: 88px;
}

.marker_small .bgSign {
  background-size: 0px;
  mask-size: 0px;
  margin-top: 8px;
  width: 0px;
  height: 0px;
}

.marker_big .bgSign,
.marker_normal .bgSign {
  mask-size: contain;
  -webkit-mask-size: contain;
}

.marker_big .bgSign {
  margin-top: 18px;
  width: 31px;
  height: 31px;
}

.flex1 {
  flex: 1;
}

.fcol {
  flex-direction: column;
}

.clearfixHack {
  position: static;
}

.registerHead {
  background: var(--viewBg);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.mleft8 {
  margin-left: 8px;
}

.activity {
  //overflow: hidden;
  position: fixed;
  left: 0;
  top: 100%;
  width: 100vw;
  min-height: 100%;
  background: var(--viewBg);
  border-radius: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin: auto;
  padding: 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.175);
  transform-origin: top center;
}

.scrubBar {
  display: block;
  position: absolute;
  top: -1rem;
  left: calc(50% - 2rem);
  height: 0.25rem;
  width: 4rem;
  border-radius: 0.25rem;
  background-color: #3c3c3c;
  opacity: 0.8;
  margin: 0 auto;
}

.tapZone {
  position: absolute;
  top: -1rem;
  width: 100%;
  height: 100px;
}

.modalShader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation: shadein 0.5s;
  -moz-animation: shadein 0.5s;
  animation: shadein 0.5s;
}

@-webkit-keyframes shadein {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
@-moz-keyframes shadein {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
@keyframes shadein {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

/*
 * Button Main
 */

.btnMain {
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  background-color: var(--accentColor);
  border: none;
  color: var(--fontInvertedColor);
  border-radius: calc(var(--borderRadius) / 2);
  padding: 12px 0px 10px 0px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  margin-bottom: 16px;
}

.btnMain:last-child {
  margin-bottom: 0px;
}

/*
 * Others...
 */

.iconType {
  display: inline-block;
  margin-right: 8px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  background-color: var(--viewBg);
  z-index: 3;
  /* background: var(--theme-color-accent); */
  margin-bottom: 24px;
  /* transition: all 0.6s; */
}

.sticked {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: var(--accentColor);
  z-index: 3;
  /* background: var(--theme-color-accent); */
  box-shadow: none;
  transition: all 0.6s;

  .titleH2_1,
  .price {
    .iconType div {
      background-color: var(--fontInvertedColor);
    }
    color: var(--fontInvertedColor);
  }
}

.sticked .subtitle {
  transition: all 1s;
  height: 0;
  overflow: hidden;
}

.title__wrap {
  display: flex;
  justify-content: space-between;
  padding: $dp;
  align-items: flex-start;
}

.price {
  margin-left: 8px;
  white-space: nowrap;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: var(--fontCaptionColor);
}

.react-tabs {
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
}

.react-tabs__list {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  padding: 0 16px;
}

.react-tabs__title {
  margin-right: 32px;
  font-size: 0.875em;
  color: var(--fontCaptionColor);
}

.react-tabs__tab-panel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.react-tabs__tab--selected {
  color: var(--fontColor) !important;
  border-bottom: 2px solid var(--accentColor);
}

.tab-wrap {
  height: 100%;
}

.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.tab-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}

.tab-content {
  padding: 0 25px 0px;
  position: relative;
}

.reset {
  font-size: 0.625em;
  color: #c4c4c4;
  right: 25px;
  top: 00px;
  position: absolute;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.selectedStr {
  color: #ff00fe;
}

/* FONTS */

h1,
.titleH1 {
  font-family: $bodyFontFamily;
  font-size: 34px;
  line-height: 41px;
  color: var(--fontColor);
  font-weight: bold;
}

h2,
.titleH2 {
  font-family: $bodyFontFamily;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  color: var(--fontColor);
}

.titleH2_1 {
  font-family: $bodyFontFamily;
  font-size: 22px;
  line-height: 24px;
  font-weight: normal;
  color: var(--fontColor);
}

h3,
.H3 {
  font-family: $bodyFontFamily;
  font-size: 22px;
  line-height: 26px;
  font-weight: normal;
  color: var(--fontColor);
}

h4,
.H4 {
  margin: 0;
  font-family: $bodyFontFamily;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: var(--fontColor);
}

.H4map {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.body1 {
  font-family: $bodyFontFamily;
  font-size: $bodyFontSize;
  line-height: $bodyLineHeight;
  // font-weight: 600;
  color: var(--fontInvertedColor);
}

.body2 {
  font-family: $bodyFontFamily;
  font-size: $bodySubFontSize;
  line-height: $bodySubLineHeight;
  // font-weight: 600;
  color: var(--fontColor);
}

.body3 {
  font-family: $bodyFontFamily;
  font-size: $bodySubFontSize;
  line-height: 20px;
  font-weight: normal;
  color: #393939;
}

.caption1 {
  font-family: $bodyFontFamily;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: var(--theme-shade2);
}

.caption2 {
  font-family: $bodyFontFamily;
  font-size: 13px;
  line-height: 14px;
  font-weight: bold;
  color: var(--fontColor);
}

.actionBold {
  font-family: $bodyFontFamily;
  font-size: 20px;
  font-weight: bold;
  color: var(--accentColor);
}

.action {
  font-family: $bodyFontFamily;
  font-size: 20px;
  font-weight: 500;
  color: var(--taccentColor);
}

.tag1 {
  font-family: $bodyFontFamily;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  color: var(--fontColor);
}

.tabbar {
  font-family: $bodyFontFamily;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: var(--fontColor);
}

/* END FONTS */

.confirmationUi {
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
  width: 100vw;
  height: calc(100vh);
  background: var(--mainBg);
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: var(--fontColor);
}

.confirmationUi {
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationBox {
  -webkit-overflow-scrolling: touch;
  position: relative;
  margin: 0 auto;
  max-width: 330px;
  max-height: calc(100vh - 100px);
  width: calc(100% - 48px);
  min-height: 288px;
  box-shadow: 0 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--accentColor);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &_newYear {
    background: #eaeef6 url('../assets/holidays/newYear/winter_modal_bg_image.png') center / cover no-repeat;
    padding-top: 30px;
    --fontColor: #000;

    &:before {
      content: '';
      position: absolute;
      top: -27px;
      background-image: url('../assets/holidays/newYear/winter_modal_decor_top.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 355px;
      width: calc(100% + 32px);
      height: 92px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -41px;
      background-image: url('../assets/holidays/newYear/winter_modal_decor_bottom.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 355px;
      width: calc(100% + 32px);
      height: 62px;
    }
  }

  &_valentineDay {
    background: #fbfbfb;
    padding-top: 30px;
    --fontInvertedColor: var(--fontColor);
    overflow: hidden;

    > div {
      position: relative;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      background-image: url('../assets/holidays/valentineDay/popup-valentine-top.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 355px;
      width: 100%;
      height: 119px;
      z-index: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      background-image: url('../assets/holidays/valentineDay/popup-valentine-bottom.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 355px;
      width: 100%;
      height: 119px;
      z-index: 0;
    }
  }
}

.header {
  height: 48px;
  background-color: var(--viewBg);
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fontColor);
}

.content {
  max-height: calc(100vh - 48px);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  position: relative;
}

.navbarIconWrapper32 {
  position: fixed;
  width: 32px;
  height: 32px;
  top: calc(#{$dp} / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  z-index: 2;

  &.left {
    left: $dp;
  }
  &.right {
    right: $dp;
  }
}

.closeIconSVG {
  stroke: var(--fontColor);
}

.closeIcon {
  position: absolute;
  left: 10px;
}

.leftAbsolute {
  left: $dp;
  position: absolute;
}

.rightAbsolute {
  right: $dp;
  position: absolute;
}

.exitIcon {
  left: 10px;
  position: absolute;
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/icons/close_black.svg');
}

.titleStyle {
  margin-top: 12px;
}

.settingsImgPlaceholder {
  margin-top: 24px;
  width: calc(100vw - 40px);
  height: 140px;
}

.settingsWrapper {
  bottom: 0px;
  width: 100vw;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsImg {
  width: calc(100vw - 88px);
  height: 140px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: var(--brandingBackgroundImage);
}

.snackBody {
  min-width: 288px;
  background-color: #e06e6e;

  margin: 8px;

  display: flex;
  align-items: center;

  padding: 6px 16px;
  border-radius: 4px;

  color: #fff;
  font-size: 14px;
}

.snackWrapper {
  position: fixed;
  opacity: 0;
  top: 24px;
  left: 0px;
  width: 100%;

  display: flex;
  justify-content: center;
  z-index: -5;

  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.snackIcon {
  margin-right: $dp * 0.5;
}

.snackWrapperShow {
  opacity: 1;
  z-index: 999;
}

.actionBtn {
  display: flex;
  align-items: center;
  height: 3rem;
}

.actionBtn .icon {
  margin-right: 1rem;
}

.sticked .endStock {
  color: black;
}

.menuClosed {
  background-color: #dadada;
}

.menuTitle {
  // margin-top: 12px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.custom1 {
  -webkit-overflow-scrolling: touch;
}

.badgeLeftWrap {
  left: 56px;
}

.badgeRightWrap {
  right: 56px;
}

.tabcontBar {
  position: relative;
}

.timeWrap {
  position: absolute;
  top: 0px;
  background: var(--viewBg);
  border-radius: 0px 0px 8px 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */

  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0px 8px;
  display: flex;
}

.brandBgAbout {
  background-color: var(--viewBg);
}

.headerBrandBgAbout {
  background-color: var(--viewBg);
  color: var(--fontColor);
}

.timeWrap2 {
  background-color: var(--viewBg);
  border-radius: 0px 0px 8px 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */

  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0px 8px;
  display: flex;
}

@keyframes straightLine {
  50% {
    transform: translate3D(100px, -100px, 0);
  }
}

@keyframes yAxis {
  10% {
    animation-timing-function: cubic-bezier(0.22, 0.5, 0.71, 1);
    transform: translateY(-50px);
  }
  50% {
    animation-timing-function: cubic-bezier(0.04, 0.49, 0.499, 1);
    transform: translateY(100vh);
  }
}

@keyframes xAxis {
  10% {
    animation-timing-function: cubic-bezier(0.08, 0.287, 0.37, 0.64);
    transform: translateX(100px);
  }
}

.animPathEnd {
  animation: xAxis 25s cubic-bezier(0.05, 0.01, 0.21, 1);
  animation-fill-mode: forwards;
  position: absolute;
  bottom: 32px;
  left: 24px;
}

.animPathEnd div {
  animation: yAxis 25s cubic-bezier(0.7, 0.27, 0.07, 1.64);
  animation-fill-mode: forwards;
}

.animPathStart {
  position: absolute;
  bottom: 32px;
  left: 24px;
}

.sbutton {
  font-size: 20px;
  line-height: 25px;
  color: var(--fontInvertedColor);
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  display: block;
  width: 100%;
  text-align: center;
  padding: 12px 0 12px 0;
  // margin-bottom: 40px;
}

.pay {
  background: var(--accentColor);
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
}

.unpay {
  background: #dadada;
}

.warn {
  background-color: var(--warningColor);
  color: white;
}

@media (min-width: 1025px) {
  .navbarIconWrapper32 {
    position: absolute;
  }
  .wrapper::-webkit-scrollbar {
    display: none;
  }
  .wrapper {
    position: absolute;
  }
  .activity {
    position: absolute;
    width: 404px;
  }
  .bgWrapper {
    // left: calc(50vw - 199px);
    // top: 48px;
    // width: 395px;
    // height: calc(86vh - 40px);
  }
  .bgIcon {
    height: 195px;
    width: 338px;
    left: calc(50% - (338px / 2));
  }
  .confirmation {
    width: 404px;
    height: calc(86vh - 40px);
    position: absolute;
  }
  .content {
    max-height: calc(86vh - 48px);
    width: 404px;
    height: 86vh;
  }
  .content::-webkit-scrollbar {
    display: none;
  }

  .contentWrapper {
    min-height: calc(86vh - 48px);
    position: relative;
  }
  .snackWrapper {
    position: absolute;
  }
  .settingsImgPlaceholder {
    margin-top: 24px;
    width: calc(300px);
    height: 140px;
  }

  .settingsWrapper {
    bottom: 0px;
    width: 404px;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .settingsImg {
    width: calc(300px);
    height: 140px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: var(--brandingBackgroundImage);
  }
}

.slick-slide {
  height: 100vw;
}

@function set-color($color) {
  @if (lightness($color) > 40) {
    @return #000;
  } @else {
    @return #fff;
  }
}
