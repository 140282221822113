.sectionLabel {
  font-size: 14px;
  line-height: 32px;
  margin-bottom: 8px;
  color: var(--fontColor);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.searchBody {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.searchCenter {
  line-height: 19px;
  text-align: center;
  margin-top: 24px;
  color: var(--fontCaptionColor);
  font-size: 16px;
}
