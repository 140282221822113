@import 'src/styles/variables.scss';

.searchBarIcon {
  position: absolute;
  right: 16px;
}
.menuNavbarInput {
  background: #ffffff;
  border: none;
  outline: none;
}

.right {
  justify-content: flex-end;
}

.searchBarInput {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

.menuSearch {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 48px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 16px;
}

.closeIcon {
  position: absolute;
  left: 10px;
  top: 10px;

  width: 26px;
  height: 26px;
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/close_black.svg');
}
.title {
  margin-top: 12px;
  font-family: $bodyFontFamily;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.subtitle {
  font-family: $bodyFontFamily;
  font-size: 14px;
  line-height: 18px;
}

.listWrapper {
  margin-top: 24px;
  margin-bottom: 52px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}

.card {
  display: flex;
  justify-items: start;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  max-height: 89px;
  background-color: var(--viewBg);
  color: var(--fontColor);
  /* transition: all 0.5s ease; */
}

.row {
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.commBox {
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: var(--fontColor);
  width: 100%;

  border-radius: 4px;
  border: var(--fontColor);
  padding: 8px;
}

.smolOrd {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.flex {
  display: flex;
  align-items: center;
}
.jcStart {
  justify-content: flex-start;
}
.jcEnd {
  justify-content: flex-end;
}
.flex1 {
  flex: 1;
}
.flex3 {
  flex: 3;
}

.venue {
  /* caption */

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  // color: var(--history-card-color, #000000);
}

.tableid {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  // color: #262626;
  // fill: #c4c4c4;
  display: flex;
  align-items: center;
}
.tableid > svg {
  margin-right: 0.25rem;
}

.venue.vis,
.tableid.vis,
.tableid.vis > svg {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  text-align: right;

  /* asphalt--color */

  // color: var(--history-card-header-sel-color, #262626);
}

.price {
  grid-area: price;
}
.price::after {
  content: '₴';
}

.date {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: right;

  // color: var(--history-card-color, #000000);
}

.orderid {
}

.details {
  text-align: left;
  align-self: start;
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 1.3rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(calc(100vw - 5rem));
}
.details.wrap {
  white-space: normal;
}

.actions {
  grid-area: actions;
  line-height: 2.25rem;
  width: 100%;
  border-top: 0.5px dashed black;
}

.actions.press {
  filter: brightness(50%);
}

.exitIcon {
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/exitIcon.svg');
}

.paymentPanel {
  width: 100%;
  max-width: 296px;
}

.visible {
  grid-template-rows: 1rem 2.5rem auto 2rem;
  max-height: 5500px;
  background-color: var(--accentColor);
  color: var(--fontInvertedColor);
  box-shadow: none;
}

.smallText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}

.phr {
  border: 0.5px dashed var(--fontColor);
  width: 100%;
  height: 1px;
  margin: 25px 16px 8px 16px;
}

.somepText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #000000;
}

.iconClass {
  fill: var(--fontColor);
}

@media (min-width: 1025px) {
  .details {
    width: 340px;
  }
}
