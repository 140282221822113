.segmentSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  border: 1px solid var(--fontCaptionColor);
  padding: 4px;
  margin-bottom: 10px;
  position: relative;

  &Option {
    height: 32px;
    flex: 1;
    text-align: center;
    border-radius: 32px;
    color: var(--fontCaptionColor);
    font-size: 13px;
    line-height: 32px;
    font-weight: 500;
    position: relative;
    transition: color 0.2s ease-out;
    will-change: color;

    &Label {
      z-index: 5;
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &Disabled {
      color: #cccccc;
    }

    &Active {
      color: var(--fontInvertedColor);
    }
  }

  &Marker {
    width: 100%;
    height: 32px;
    border-radius: 16px;
    background: var(--accentColor);
    position: absolute;
    left: 4px;
    top: 3px;
    transition: all 0.3s ease;
    will-change: transform;
  }
}
