@import 'src/styles/variables.scss';
/*
 * Card Skeleton for Loading
 */

.richMenuWrapper {
  background: var(--mainBg);
  position: static !important;
  height: 100vh !important;

  &_newYear {
    background: url('../../assets/holidays/newYear/winter_main_bg.png') center / cover no-repeat;
  }
}

.loadTab {
  margin: 0 8px;
  width: 64px; //demo
  min-width: 64px;
  height: 64px;

  &:empty::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
    background-color: var(--viewBg);
  }
}

// .card {
//   width: 280px; //demo
//   height: var(--card-height);

//   &:empty::after {
//     content: '';
//     display: block;
//     width: 100%;
//     height: 100%;
//     border-radius: 6px;
//     box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);

//     background-image: linear-gradient(90deg, rgba(lightgrey, 0) 0, rgba(lightgrey, 0.8) 50%, rgba(lightgrey, 0) 100%),
//       //animation blur
//         var(--title-skeleton),
//       //title
//         var(--desc-line-skeleton),
//       //desc1
//         var(--desc-line-skeleton),
//       //desc2
//         var(--avatar-skeleton),
//       //avatar
//         var(--footer-skeleton),
//       //footer bar
//         var(--card-skeleton); //card

//     background-size: var(--blur-size), var(--title-width) var(--title-height),
//       var(--desc-line-1-width) var(--desc-line-height), var(--desc-line-2-width) var(--desc-line-height),
//       var(--avatar-size) var(--avatar-size), 100% var(--footer-height), 100% 100%;

//     background-position: -150% 0,
//       //animation
//         var(--title-position),
//       //title
//         var(--desc-line-1-position),
//       //desc1
//         var(--desc-line-2-position),
//       //desc2
//         var(--avatar-position),
//       //avatar
//         var(--footer-position),
//       //footer bar
//         0 0; //card

//     background-repeat: no-repeat;
//     animation: loading 1.5s infinite;
//   }
// }

// @keyframes loading {
//   to {
//     background-position: 350% 0, var(--title-position), var(--desc-line-1-position), var(--desc-line-2-position),
//       var(--avatar-position), var(--footer-position), 0 0;
//   }
// }

// @keyframes tabloading {
//   to {
//     background-position: 350% 0, var(--tab-icon-position), 0 0;
//   }
// }

// SKELETON

.catLoading {
  display: flex;
  width: 100%;
  padding: 8px 0 24px 8px !important;
}

.iconSvg {
  height: $dp * 2;
  width: $dp * 2;
}

.iconSvgSmall {
  height: $dp * 1.5;
  width: $dp * 1.5;
}

.wrapper {
  z-index: 0;
  padding-bottom: 68px;
}

.stickyTabs {
  background-color: var(--viewBg);
  z-index: 2;
  width: 100%;
  top: 0px;
  position: fixed;
  transition: top 0.5s ease;

  &Expandable {
    border-radius: 0px 0px 16px 16px;
  }

  &Expanded {
    display: none;
  }
}

.rightSection {
  display: flex;
  align-items: flex-end;
  position: absolute;
  height: 100%;
  right: 0;
}

.stickyTabs_pad {
  z-index: 1;
  padding-top: 166px;
}

.stickyTabs2 {
  z-index: 1;
  padding-top: 66px;
}

.stickyTabs_pad_search {
  z-index: 1;
  padding-top: 66px;
}

.moveUp {
  top: 0px;
}

.catTabs {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 8px 0px 6px 0px;
  background-color: var(--viewBg);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.dropshadow {
  transition: box-shadow 0.4s ease;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.tab {
  display: inline-block;
  width: 64px;
  margin-left: $dp * 0.5;
  color: #000;
}
.activeTab {
  width: 80px;
}

.noIconTab {
  composes: tab;
  width: auto;
}

.tab .iconTitle,
.tab .activeTitle {
  display: flex;
  position: relative;
  justify-content: center;
}
.tab .noIconTitle {
  padding: 8px 12px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  white-space: nowrap;
  max-width: initial;
}

.tab:first-child {
  margin-left: $dp;
}

.tab:last-child {
  margin-right: $dp;
}

.marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--richMenuMarkerBg);
  box-shadow: var(--richMenuMarkerShadow);
  border-radius: 16px;
  z-index: -1;
}

.labelMarker {
  composes: marker;
}

.iconBox {
  position: relative;
  width: 64px;
  height: 64px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  margin: 0 auto;
  margin-bottom: 4px;
}

.iconTitle {
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: var(--fontCaptionColor);
  max-width: 72px;
  white-space: normal;
}

.activeTitle {
  opacity: 1;
  font-size: 12px;
  color: var(--fontColor); // #FFE700;
}

.customIcon {
  background-color: transparent;
}

.hiddenBox {
  visibility: hidden;
}

.subcatTabs {
  padding: 10px 10px;
}

.tagsFilter {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.tagsFilterButton {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.subcatTabs > div {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  margin-right: 10px;
  background: var(--viewBg);
  color: var(--fontColor);
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid var(--fontCaptionColor);
  border-radius: 6px;
  padding: 0 10px;
}

.activeSubtab {
  background: var(--accentColor) !important;
  border-color: var(--accentColor) !important;
  color: var(--fontInvertedColor) !important;
}

.promoCard {
  background: palegreen;
  height: 128px;
  margin-bottom: 20px;
}

.menuCard {
  overflow: hidden;
  width: calc(100% - 16px);
  background: var(--viewBg);
  margin-bottom: 8px;
  border-radius: calc(var(--borderRadius) / 2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.menuCard .img {
  display: flex;
  justify-content: space-between;
  height: 132px;
  width: 100%;
  background: var(--viewBg);
  border-top-left-radius: calc(var(--borderRadius) / 2);
  border-top-right-radius: calc(var(--borderRadius) / 2);
  background-size: cover;
  background-position: center center;
}

.like {
  position: absolute;
  right: 0px;
  padding: $dp * 0.5;
  top: 0px;
}

.menuCard .like {
  right: calc(100% - 48px);
  padding: $dp * 0.5;
}

.iconWrapper {
  width: 24px;
  height: 24px;
}

.newLike div {
  position: absolute;
  width: 32px;
  height: 32px;
  top: calc(#{$dp} / 4);
  right: calc(#{$dp} / 4);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}

.menucardBadge {
  padding: 0 4px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
  margin-right: 2px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  color: var(--fontColor);
  background: var(--viewBgLighten);
  display: flex;
  align-items: center;
  justify-content: center;
}

.attention {
  background: var(--attentionBgColor);
  color: #ffffff;
  padding: 3px 8px;
}

.preoderStart {
  background-color: #00b2ff;
}

.menucardBadge {
  &:first-child {
    border-top-left-radius: calc(var(--borderRadius) / 6);
  }

  &:last-child {
    margin-right: 0;
  }
}

.iconDish {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 24px;
  background: var(--accentColor);
  font-weight: 600;
  color: var(--fontInvertedColor);
}

.iconDishFavorite {
  composes: iconDish;
  padding-left: 12px;
}

.timeDisabled {
  color: white;
  background-color: rgba(0, 0, 0, 0.45);
  background-blend-mode: multiply;
}

.textArea {
  min-height: 56px;
  overflow: hidden;
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: var(--viewBg);
  color: var(--fontColor);
}

.areaInnerWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
}

.price {
  white-space: nowrap;
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: right;
  letter-spacing: -0.05em;

  color: var(--fontColor);
}

.disabledDishPrice {
  color: var(--fontCaptionColor);
}

.dishMax {
  overflow: hidden;
  max-height: 80px;
}

.dishTitleWrapper {
  display: flex;
  max-width: calc(100% - 76px);
  flex: 1;
}

.dishTitleWithOptionsWrapper {
  display: flex;
  max-width: calc(100% - 76px);
  flex: 1;
  flex-direction: column;
}

.dishTitleInnerWrapper {
  display: flex;
  position: relative;

  .dishTitle {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.dishTitle {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  max-height: 40px;
  color: var(--fontColor);
  width: calc(100% - 32px);
}

.dishDesc {
  margin-left: 32px;
  max-height: 1rem;
  overflow: hidden;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* or 123% */
  color: var(--fontColor);
}

.menuNavbarInput {
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  margin: 6px 44px 6px 16px;
  background: #ffffff;
}

.menuNavbarInput input {
  margin: 7px 8px 7px 0px;
  background: #ffffff;
  border: none;
  outline: none;
  color: var(--fontColor);
}

.iconClass {
  fill: var(--fontCaptionColor);
}

.searchIconClass {
  margin: 6px 8px;
  fill: var(--fontColor);
}

.menuNavbar {
  background-color: var(--viewBg);
  display: flex;
  height: $dp * 3;
  justify-content: space-between;
  padding: 4px 20px 4px 20px;
}

@supports (backdrop-filter: none) {
  .menuNavbar {
    backdrop-filter: blur(40.7742px);
  }
  .catTabs {
    backdrop-filter: blur(40.7742px);
  }
}

@supports not (backdrop-filter: none) {
  .menuNavbar {
    background-color: #ffffff;
  }
  .catTabs {
    background-color: #ffffff;
  }
}

.menuNavbar div {
  display: flex;
  align-items: center;
}

.menuSearch {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  height: $dp * 3;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  padding: 0px $dp 0px $dp;
}

.logoWrapper {
  flex-direction: column;
  justify-content: center;

  &_defaultHoliday {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.logoWrapperTitle {
  flex-direction: column;
}

.titleIcon {
  &_defaultHoliday {
    display: block;
    margin-right: $dp;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &:last-of-type {
      margin-left: $dp;
      margin-right: 0;
    }
  }
  &_newYear {
    background-image: url('../../assets/holidays/newYear/winter_navbar_decor_icon.png');
    width: 21px;
    height: 28px;
    transform: rotate(-15deg);

    &:last-of-type {
      transform: scaleX(-1) rotate(-15deg);
    }
  }

  &_valentineDay {
    background-image: url('../../assets/holidays/valentineDay/valentine-navbar-icon-dark.png');
    width: 40px;
    height: 25px;

    &Light {
      background-image: url('../../assets/holidays/valentineDay/valentine-navbar-icon-light.png');
    }

    &:last-of-type {
      transform: scaleX(-1);
    }
  }
}
.logoWrapper .title {
  color: var(--fontColor);
  font-size: 22px;
  line-height: 1;
  font-weight: 600;
  min-height: 24px;
  max-height: 30px;
  justify-content: center;
  width: 100%;

  img {
    display: block;
    max-width: 100%;
    height: 100%;
  }
}

.logoWrapper .subtitle {
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  color: var(--fontColor);
  opacity: 0.75;

  &.subtitleHoliday {
    display: block;
    text-align: center;

    &Closed {
      font-size: 11px;
      line-height: 1;
    }
  }
}

.right {
  justify-content: flex-end;
}

.rightMarg {
  margin-left: $dp;
}

.rightMarg2 {
  margin-left: $dp * 2;
}

.searchBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 100px);
}

.searchCenter {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $bodyFontFamily;
  font-size: 32px;
  line-height: 40px;
  text-align: center;

  color: var(--fontCaptionColor);

  max-width: 290px;
}

.loyalty {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffdaf0;
}

.customSnackPosition {
  top: 0px;
}

.disabledDish .selectedStr {
  color: orange;
}

.disabledDish {
  color: #9e9e9e;
}

.flat {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 32px;
}

.emoji {
  font-size: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.activeEmoji {
  font-size: 48px;
}

.notEmoji {
  font-size: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.activeNotEmoji {
  font-size: 48px;
}

.markerDef {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.favorite {
  background-image: var(--cats-favorite-img, url('../../assets/emoji/heavy-black-heart_2764.png'));
}

.activeTab .favorite {
  background-image: var(--cats-favorite-active-img, url('../../assets/emoji/heavy-black-heart_2764.png'));
}

.matcha {
  background-image: var(--cats-matcha-img, url('../../assets/emoji/matcha.png'));
}

.activeTab .matcha {
  background-image: var(--cats-matcha-active-img, url('../../assets/emoji/matcha.png'));
}

.cake {
  background-image: var(--cats-cake-img, url('../../assets/emoji/cake_icon.png'));
}

.activeTab .cake {
  background-image: var(--cats-cake-active-img, url('../../assets/emoji/cake_icon.png'));
}

.popular {
  background-image: var(--cats-popular-img, url('../../assets/emoji/popular.png'));
}

.activeTab .popular {
  background-image: var(--cats-popular-active-img, url('../../assets/emoji/popular.png'));
}

.beverages2 {
  background-image: var(--cats-beverages2-img, url('../../assets/emoji/napitki.png'));
}

.activeTab .beverages2 {
  background-image: var(--cats-beverages2-active-img, url('../../assets/emoji/napitki.png'));
}

.coffee {
  background-image: var(--cats-coffee-img, url('../../assets/emoji/hot-beverage_2615.png'));
}

.activeTab .coffee {
  background-image: var(--cats-coffee-active-img, url('../../assets/emoji/hot-beverage_2615.png'));
}

.breakfast {
  background-image: var(--cats-breakfast-img, url('../../assets/emoji/cooking_1f373.png'));
}

.activeTab .breakfast {
  background-image: var(--cats-breakfast-active-img, url('../../assets/emoji/cooking_1f373.png'));
}

.burger {
  background-image: var(--cats-burger-img, url('../../assets/emoji/sandwich_1f96a.png'));
}

.activeTab .burger {
  background-image: var(--cats-burger-active-img, url('../../assets/emoji/sandwich_1f96a.png'));
}

.salad {
  background-image: var(--cats-salad-img, url('../../assets/emoji/salad.png'));
}

.activeTab .salad {
  background-image: var(--cats-salad-active-img, url('../../assets/emoji/salad.png'));
}

.bakery {
  background-image: var(--cats-bakery-img, url('../../assets/emoji/croissant_1f950.png'));
}

.activeTab .bakery {
  background-image: var(--cats-bakery-active-img, url('../../assets/emoji/croissant_1f950.png'));
}

.bread {
  background-image: var(--cats-bread-img, url('../../assets/emoji/bread_1f35e.png'));
}

.activeTab .bread {
  background-image: var(--cats-bread-active-img, url('../../assets/emoji/bread_1f35e.png'));
}

.alcohol {
  background-image: var(--cats-alcohol-img, url('../../assets/emoji/wine-glass_1f377.png'));
}

.activeTab .alcohol {
  background-image: var(--cats-alcohol-active-img, url('../../assets/emoji/wine-glass_1f377.png'));
}

.soda {
  background-image: var(--cats-soda-img, url('../../assets/emoji/tab-icon-soda.svg'));
}

.activeTab .soda {
  background-image: var(--cats-soda-active-img, url('../../assets/emoji/tab-icon-soda.svg'));
}

.napitki {
  background-image: var(--cats-napitki-img, url('../../assets/emoji/tab-icon-beverages.svg'));
}

.activeTab .napitki {
  background-image: var(--cats-napitki-active-img, url('../../assets/emoji/tab-icon-beverages.svg'));
}

.pancake {
  background-image: var(--cats-pancake-img, url('../../assets/emoji/pancake.png'));
}

.activeTab .pancake {
  background-image: var(--cats-pancake-active-img, url('../../assets/emoji/pancake.png'));
}

.postman {
  background-image: var(--cats-postman-img, url('../../assets/emoji/postman.png'));
}

.activeTab .postman {
  background-image: var(--cats-postman-active-img, url('../../assets/emoji/postman.png'));
}

.starter {
  background-image: var(--cats-starter-img, url('../../assets/emoji/oden.png'));
}

.activeTab .starter {
  background-image: var(--cats-starter-active-img, url('../../assets/emoji/oden.png'));
}

.easter {
  background-image: var(--cats-easter-img, url('../../assets/emoji/emoji-eastern-144.png'));
}

.activeTab .easter {
  background-image: var(--cats-easter-active-img, url('../../assets/emoji/emoji-eastern-144.png'));
}

.fork {
  background-image: var(--cats-fork-img, url('../../assets/emoji/fork-and-knife-with-plate_1f37d.png'));
}

.dutyfree {
  background-image: var(--cats-dutyfree-img, url('../../assets/emoji/dutyfree.png'));
}

.ny {
  background-image: var(--cats-ny-img, url('../../assets/emoji/elochka.png'));
}

.activeTab .fork {
  background-image: var(--cats-fork-active-img, url('../../assets/emoji/fork-and-knife-with-plate_1f37d.png'));
}

.img_16 {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.img_24 {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.img_32 {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.img_40 {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.svgBrand {
  min-height: 24px;
  max-height: 30px;
  justify-content: center;
  width: 100%;

  img {
    display: block;
    max-width: 100%;
    height: 100%;
  }
}

.cardsWrapper {
  position: relative;
  width: 100%;
  min-height: calc(100% - 371px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brandingBottom {
  position: absolute;
  bottom: -200px;
  background-repeat: repeat-x;
  background-image: var(--brandingBackgroundImage);
  background-size: 160px;
  opacity: 0.2;
  width: 100%;
  height: 88px;
}

.img_48 {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.eliptext {
  margin-left: 8px;
  white-space: nowrap;
  max-width: calc(100vw - 160px);
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1025px) {
  .catTabs::-webkit-scrollbar {
    display: none;
  }
  .stickyTabs {
    width: 396px;
    z-index: 1;
  }
  .eliptext {
    max-width: 224px;
  }
}

.favoriteDishFavIcon {
  position: absolute;
  left: -2px;
  top: -2px;
}

.favoriteDishDetailsWrapper {
  transition: 0.3s height ease;
  height: 0;
  will-change: height;
  overflow: hidden;
  position: relative;
}

.favoriteDishDetailsInnerWrapper {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;

  .buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  button {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--fontColor);
    padding: 8px 40px;
    background-color: transparent;
    border: none;
  }
}

.optionsWrapper {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-style: normal;
  padding-left: 32px;
  font-size: 13px;
  line-height: 16px;

  &Open {
    display: flex;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.volumeWrapper {
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: var(--fontCaptionColor);
  span {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.draggerWrapper {
  height: 32px;
  position: relative;
}

.catTabsDragWrapper {
  background-color: var(--viewBg);
  border-radius: 0px 0px 16px 16px;
}

.expandableCatTabs {
  overflow: hidden !important;
}
