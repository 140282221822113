@import 'src/styles/variables.scss';

.confirmationUi {
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationBox {
  margin: 0px 24px;
  max-width: 300px;
  width: 100%;
  min-height: 288px;

  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--codescan-confirmation-bgcolor, #f9d953);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.confirmationBottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.tableFont2 {
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;

  color: var(--codescan-confirmation-color, #000000);
}

.confirmationBoxHeader {
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 160% */

  text-align: center;

  color: var(--codescan-confirmation-color, #000000);
}

.confirmationBoxHeader2 {
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 160% */

  text-align: center;

  color: var(--codescan-confirmation-color, #000000);
}

.confirmationBoxButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.vr {
  min-width: 1px;
  background-color: #b4b4b4;
  height: 56px;
}

.confirmationBoxButton {
  border-top: 1px solid #b4b4b4;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationBox1 {
  margin: 0px 16px 0px 16px;

  min-height: 248px;

  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--codescan-confirmation-bgcolor, #f9d953);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation {
  position: fixed;
  top: 0px;
  z-index: 200;
  width: 100vw;
  height: calc(100vh);
  background: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: #000;
}

.closeIcon {
  position: absolute;
  left: 10px;

  width: 36px;
  height: 48px;
  background-size: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/back.svg');
}

.setWrapper {
  max-width: calc(100vw - 80px);
  margin: 24px auto;
}

.setWrapperBg {
  composes: setWrapper;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--brandingBackgroundImage);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 170px;
}

.table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  width: calc(120px);
  height: calc(120px);
  position: relative;
  font-size: 60px;
  line-height: 176px;
  padding: 15px 0px;
}

.tableVec {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  fill: var(--accentColor);
}

.table_ind {
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(80px);
  height: calc(80px);

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/ico-qr-vec.svg');
  font-size: 60px;
  line-height: 176px;
}

.tableWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.done {
  width: calc(100vw - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9d953;
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.doneText {
  padding: 10px 0px;
}

.error {
  font-weight: 700;
  color: red;
}

.title {
  margin-top: 12px;
}

.subtitle {
  font-family: $bodyFontFamily;
  font-size: 14px;
  line-height: 18px;
}

.tutorial {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: #9e9e9e;
}

.tutorialWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 18px;
}

.list {
  width: 100%;
  max-width: calc(100vw - 64px);
  display: flex;
  flex-direction: column;
  margin: 16px auto 24px auto;
}

.item {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.item2 {
  position: relative;
  max-width: calc(100vw - 48px);
  width: 100%;
  display: flex;
  align-items: center;

  // background: #ffffff;
  // border: 1px solid #717171;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  // border-radius: 5px;

  padding: 12px 12px;
}

.item2 input,
.item2 select {
  width: 100%;
  height: 32px;
  border: none;
  flex: 1;
  // border-left: 1px solid #d1d1d1;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  background-color: transparent;
}
.item2 input::placeholder,
.item2 input::-moz-placeholder,
.item2 select::-moz-placeholder,
.item2 input::-webkit-input-placeholder,
.item2 select::-webkit-input-placeholder,
.item2 select::placeholder {
  color: #6d6d6d;
}

.item2 select {
  border: none;
  flex: 1;
  padding: 0px;
  margin: 0px;
}

.item2Active {
  box-shadow: 0px 1px 1px var(--accentColor);
  // border: 1px solid #ffcc3a;
}

.userText {
  margin-left: 1rem;
  color: var(--fontColor);
}

.userIcon {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #6d6d6d;
}

.vor {
  width: 1px;
  border-left: 1px solid #d1d1d1;
  height: 32px;
  margin-left: 4px;
}

.settingsIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
  opacity: 0.5;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
}

.colorIcon {
  width: var(--generalIconSize);
  height: var(--generalIconSize);
  background-color: var(--generalIconColor);
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

.venueIcon {
  width: 24px;
  height: 24px;
  opacity: 1;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/venueIcon.svg');
}

.orderIcon {
  width: 24px;
  height: 24px;
  opacity: 1;
  background-size: 21px 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/orderIcon.svg');
}

.aboutIcon {
  width: 24px;
  height: 24px;
  opacity: 1;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/aboutIcon.svg');
}

.jobIcon {
  width: 24px;
  height: 24px;
  opacity: 1;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/ico-job1-24.svg');
}

.workIcon {
  width: 24px;
  height: 24px;
  opacity: 1;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/icon-work.svg');
}

.contactIcon {
  width: 24px;
  height: 24px;
  opacity: 1;
  background-size: 19px 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/contactIcon.svg');
}

.userIcon2 {
  opacity: 1;
  width: 24px;
  height: 24px;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/person.svg');
}

.tablenumIcon {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/iconka.png');
}

.vrBrand {
  margin-top: 20px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.29);
  display: var(--vr-display, none);
}

.arrowBrand {
  fill: var(--settings-backIcon-fill, #000000);
}

.arrowBrand2 {
  stroke: var(--settings-backIcon-fill, #000000);
}

.unpay {
  background: #dadada;
  // border: 0.3px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  color: #979797;
}

.notable {
  margin-top: 12px;
  margin-bottom: 8px;
  width: 111px;
  height: 32px;

  background: transparent;
  border: 0.8px solid var(--fontColor);
  box-sizing: border-box;
  border-radius: 6px;
  color: var(--fontColor);

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.notable_gray {
  opacity: 1;
}

.saveProfile {
  position: absolute;
  top: 8px;
  right: 8px;

  background: var(--accentColor);
  border-radius: 6px;
  padding: 4px 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontColor);
}

.unsaveProfile {
  position: absolute;
  top: 8px;
  right: 8px;

  background: transparent;
  border-radius: 6px;
  padding: 4px 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #979797;
}

.errorPas {
  /* label-tab */
  position: absolute;
  top: 0px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */

  color: red;
}

.build {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  text-align: center;

  color: #ffffff;

  opacity: 0.5;
}

.errorPas2 {
  /* label-tab */
  padding: 4px;
  font-family: var(
    --profile-fontfamily,
    -apple-system,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif
  );
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 145% */
  text-align: left;
  background: #979797;
  border-radius: 0px 0px 2px 2px;
  color: #ffffff;
}

.inviewHead {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.errorItem {
  box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.errorItem2 {
  box-shadow: inset 0px 0px 1px 1px var(--warningColor);
}

.registerHead {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.selectWrapper {
  background: transparent;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 10px;
    background: url("../../assets/arrow_drop_down-24.svg");
    z-index: 0;
  }
}

.selectedLabel {
  position: absolute;
  top: 0px;
  left: 8px;

  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */

  color: #979797;
}

.unselectedLabel {
  position: absolute;
  display: none;
}

select.dropdp {
}

select.dropdp:invalid {
  color: #6d6d6d;
}

select.dropdp option {
  color: #000000;
}

select.dropdp1 {
  border: 1px solid var(--fontCaptionColor);
  border-radius: 0.5rem;
  color: var(--fontColor);
  position: relative;
  z-index: 1;
}

select.dropdp1:invalid {
  color: #6d6d6d;
}

select.dropdp1 option {
  color: var(--fontColor);
}

.selectArrow {
  position: absolute;
  top: 18px;
  right: 0px;
}

.buttonWrapper {
  width: 100%;
  max-width: calc(100% - 64px);
  margin: 24px auto 0px auto;
}

.setButton {
  margin-bottom: 8px;
  height: 48px;
  background: transparent;
  border: 1px solid var(--fontColor);
  box-sizing: border-box;
  border-radius: 6px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontColor);
  justify-content: center;

  position: relative;
}

.setButtonIcon {
  position: absolute;
  left: 32px;
  top: 12px;
  height: 24px;
  width: 24px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.setButtonColorIcon {
  position: absolute;
  left: 32px;
  top: 12px;
  height: 24px;
  width: 24px;
  margin-right: 12px;
  background-image: none;
  background-color: var(--fontColor);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

@media (min-width: 1025px) {
  .list {
    width: 100%;
    max-width: calc(400px - 32px);
    display: flex;
    flex-direction: column;
    margin: 16px auto 24px auto;
  }
}
