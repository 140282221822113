@import 'src/styles/variables';

.title {
  margin-top: 0px;
}

.textArea {
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  padding-bottom: 96px;

  display: flex;
  flex-direction: column;
}

.textAreaElem {
  outline: none;
  border: none;
  resize: none;
  flex: 1;

  padding: $dp;
}

.textAreaElem::placeholder {
  color: #cdcdcd;
}

.button {
  width: 100%;
  height: 52px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #f9d953;
  border-radius: 0px 0px 6px 6px;
}

.commentButon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--accentColor);
  border-radius: 6px;

  width: 93px;
  height: 32px;
}

.textButton {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontInvertedColor);
}
