@import 'src/styles/variables';

.wrapper {
  background: #f2f2f2;
  z-index: 30;
}

.containerWrap {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
  margin-bottom: 24px;
}

.priceList p.product,
.priceList p.duplicate {
  font-size: 16px;
  max-width: calc(100vw - 100px);
  width: 100%;
  line-height: 18px;
  position: relative;
  top: 1px;
}

.payWarn .product {
  color: black;
}

.favIcon {
  position: absolute;
  top: 0px;
  left: -6px;
}

.dishLabel {
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;

  color: #000000;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;
}

.optionContainer {
  display: flex;
  align-items: center;
}

.optionLabel {
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 86% */
  color: #979797;
}

.uncontextedPrice {
  display: flex;
  position: relative;
  align-items: center;
  margin-right: 16px;
  font-weight: 300;
  line-height: 24px;
  span {
    &:last-child {
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      font-size: 12px;
    }
  }
}

.uncontextBlack {
  width: 100%;
  line-height: 24px;
}

.container {
  background: #ffffff;
  margin-left: $dp;
  margin-right: $dp;
  padding: 16px;
  padding-bottom: 24px;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    96.875% calc(100% - 10px),
    93.75% 100%,
    90.625% calc(100% - 10px),
    87.5% 100%,
    84.375% calc(100% - 10px),
    81.125% 100%,
    78.125% calc(100% - 10px),
    75% 100%,
    71.875% calc(100% - 10px),
    68.75% 100%,
    65.625% calc(100% - 10px),
    62.5% 100%,
    59.375% calc(100% - 10px),
    56.25% 100%,
    53.125% calc(100% - 10px),
    50% 100%,
    46.875% calc(100% - 10px),
    43.75% 100%,
    40.625% calc(100% - 10px),
    37.5% 100%,
    34.375% calc(100% - 10px),
    31.125% 100%,
    28.125% calc(100% - 10px),
    25% 100%,
    21.875% calc(100% - 10px),
    18.75% 100%,
    15.625% calc(100% - 10px),
    12.5% 100%,
    9.375% calc(100% - 10px),
    6.25% 100%,
    3.125% calc(100% - 10px),
    0 100%
  );
}

.priceList {
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  height: inherit;
}

// .header div {
//   background-color: #ffffff;
//   border-radius: 4px;
//   font-size: 12px;
//   padding: 4px 8px;
//   margin: 8px 0;
// }

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

.info {
  display: flex;
  justify-content: flex-start;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: flex-start;

  color: #000000;
}

.count {
  font-weight: 300;
}

.x {
  padding: 0 4px;
}

.name {
}

.price {
  white-space: nowrap;
  text-align: right;
  margin-left: 5px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding-top: 2px;
  /* identical to box height */

  color: #000000;
}

.commentCheck {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #000000;
}

/* --- */
.menuNavbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 10px;
}

.menuNavbar div {
  display: flex;
  /* align-items: center; */
}

.newTitle {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.newSubtitle {
  /* caption */

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  display: flex;
  align-items: center;
  text-align: center;

  opacity: 0.5;
}

.checkRow {
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 0.5;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 10px */

  color: #797979;
}

.anotherFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

.checkTimedTakeout {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #686868;
}

.checkHR {
  background-color: #a3a3a3;
  width: 100%;
  min-height: 1px;
  margin: 8px 0px;
}

.center {
  /* justify-self: center; */
  /* flex-direction: column;
  justify-content: center; */
}

.center .title {
  font-size: 16px;
  font-weight: 400;
}

.center .subtitle {
  font-size: 10px;
  font-weight: 300;
}

.right {
  min-width: 36px;
  justify-content: flex-end;
}

.doneWrapper {
  padding-top: $dp * 4;
  padding-bottom: $dp * 4;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.done {
  width: calc(100vw - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--accentColor);
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.doneText {
  padding: 10px 0px;
}

.tableNum {
  font-family: $bodyFontFamily;
  font-size: 140px;
  line-height: 176px;
  padding: 15px 0px;
}

.table {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 80px);
  background: #ffffff;
  border-radius: 15px;
}

.tableWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contextedWrapper {
  min-height: 40px;
  padding: 12px 8px 12px 8px;
  border-radius: 4px;
}

.payWarn {
  background-color: var(--warningColor);
  color: white;
}

.unpaidersColor {
  background-color: #5aacfc !important;
}

.drawerWarn {
  color: var(--warningColor);
}

.warnPopup {
  background: var(--warningColor);
  color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bigFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
}

.smallFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.hor {
  border: 1px solid #b4b4b4;
  width: 100%;
}

.loadTitle {
  // position: absolute;
  // margin: 15vw;
  margin-top: 32px;
  text-align: center;
}

.load {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.load div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ff0064;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.load div:nth-child(1) {
  left: 6px;
  animation: load1 0.6s infinite;
}
.load div:nth-child(2) {
  left: 6px;
  animation: load2 0.6s infinite;
}
.load div:nth-child(3) {
  left: 26px;
  animation: load2 0.6s infinite;
}
.load div:nth-child(4) {
  left: 45px;
  animation: load3 0.6s infinite;
}
@keyframes load1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes load3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes load2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.payWrapper {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgb(242, 242, 242);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 1025px) {
    width: 396px;
  }
}

.pay {
  font-size: 20px;
  line-height: 25px;
  background: #f9d953;
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 290px;
  margin: 0px auto 0px auto;
  padding: 12px 0 12px 0;
}

.pay:hover {
  background: #fbd121;
}
