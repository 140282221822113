@import 'src/styles/variables.scss';

.drawer {
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: var(--shoppingCartEmptyBgColor);
  color: var(--shoppingCartEmptyColor);
}

.drawer.closed__empty,
.drawer.closed__full {
  bottom: -50px;
  height: 100vh;
}

.drawer.closed__empty {
  background: var(--shoppingCartEmptyBgColor);

  .title {
    color: var(--shoppingCartEmptyColor);

    .tableLayout svg path {
      fill: var(--shoppingCartEmptyColor);
    }
  }
}

.drawer.closed__full:not(.drawer.notOpen) {
  background: var(--shoppingCartBgColor);

  .title {
    color: #fff;
  }
}

.drawer.open__full {
  top: 70px;
  background: var(--viewBg);
  color: var(--fontColor);

  .title {
    color: inherit;
  }
}

.os0 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */

  text-align: right;

  color: #24ae43;
}

.os01 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */

  text-align: right;

  color: #979797;
}

.os1 {
  width: 100px;
  height: 24px;

  /* cta--color */

  background: #979797;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  text-transform: uppercase;

  color: #ffffff;
}

.os2 {
  width: 100px;
  height: 24px;

  /* gray */

  background: #24ae43;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  text-transform: uppercase;

  color: #ffffff;
}

.os3 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */
  color: #24ae43;
}

.os31 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */
  color: #979797;
}

.title2 {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 60px;
  max-width: 100%;
}

.leftBord {
  background: linear-gradient(90deg, rgba(38, 38, 38, 0) 0%, #000000 89.58%);
  border-radius: 0px 16px 0px 0px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.rightBord {
  left: inherit;
  right: 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.wrapper2 {
  // position: absolute;
  background: linear-gradient(90deg, #000000 0%, #202020 16.68%, #393939 50.35%, #212121 88.48%, #000000 100%);
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 4;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smolOrd {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
  height: 100%;
  color: #ffffff;
}

.smolOrdActive {
  background: #f9d953;
  color: #000000;
}

.favIcon {
  position: absolute;
  top: 0px;
  left: -6px;
}

.drawer.open__empty {
  height: 301px;
  margin-top: 0;
  background-image: var(--shoppingCartEmptyBg);
  background-repeat: no-repeat;
  color: var(--shoppingCartEmptyColor);
  background-size: auto;
  background-position: right center;
  background-color: var(--shoppingCartEmptyBgColor);
}

.drawer {
  &.open__empty, &.closed {
    .holidayDecorationTop {
      &_newYear {
        position: absolute;
        top: -7px;
        right: -9px;
        background-image: url("../../assets/holidays/newYear/winter_cart_decoration_top.png");
        width: 67px;
        height: 58px;
        z-index: 3;
      }
    }
  }
}

.drawer_nm.open__empty {
  background-image: url('../../assets/brandSign.png');
}

.drawer_hlbn.open__empty {
  background-image: url('../../assets/man-white.svg');
}

.shader {
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.shader.open {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.shader.open.empty {
  display: none !important;
}

.shader.closed {
  display: none;
}

.icoSleep {
  width: 26px;
  height: 26px;
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/ico-sleep@2x.png');
}

.combieText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontColor);
}

.iconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  fill: var(--fontInvertedColor);
}

.enIcon {
  fill: var(--fontInvertedColor);
  opacity: 1;
}

.enIcon2 {
  fill: var(--fontInvertedColor);
  opacity: 0.3;
}

.disIcon {
  fill: #ffffff;
  opacity: 0.2;
}

.contextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--fontInvertedColor);

  &Disabled {
    background: #e3e3e3;
    color: #fff;
  }
}

.content {
  // position: relative;
  padding: 0rem 0 100vh 0;
}

.openEmpty {
  padding: 0 1rem;
  overflow: hidden;
}

.openEmpty .title,
.openEmpty p {
  display: block;
  margin-right: 50%;
}

.openEmpty .title {
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 10px;
  color: var(--shoppingCartEmptyColor);
}

.openEmpty p {
  font-size: 14px;
  line-height: 18px;
  color: var(--shoppingCartEmptyColor);
}

.icon {
  fill: var(--shoppingCartEmptyColor);
  float: right;
  margin-right: -16px;
}

.title {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.accordionClosed .priceList {
  height: 0;
  overflow: hidden;
}

.icon {
  font-size: 26px;
  line-height: 32px;
}

/* --------- */

ul,
li,
p {
  margin: 0;
  padding: 0;
}

.br {
  height: 9px;
}

.mtop1 {
  margin-top: 1rem;
}
.mtop2 {
  margin-top: 2rem;
}
.mtop3 {
  margin-top: 3rem;
}
.mtop4 {
  margin-top: 4rem;
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.item {
  padding: 0px 16px;
  // box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1);
}

.paidSectionLight .item:first-child {
  padding-top: 16px;
  box-shadow: none;
}

.unpaidSection.item:first-child {
  margin-top: 0px;
}

.unpaidSection.item:first-child .priceList {
  margin-top: 16px;
}

.item:first-child .priceList {
  margin-top: 0px;
}

.checkLabel {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  color: var(--fontColor);
}

.checkControl {
  margin-top: 70px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg rect {
    fill: #979797;
    opacity: 0.5;
  }

  &Active {
    svg rect {
      fill: var(--accentColor);
    }
  }
}

.title {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.arrow {
  width: 25.35px;
  height: 25.35px;
  background-color: #eaeaea;
  border-radius: 50%;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  color: #858585;
}

.accordionClosed .arrow {
  transform: scale(-1);
}

.priceList {
  position: relative;
  margin-top: $dp;
  padding-bottom: 40px;
}

.subtitle {
  font-size: 10px;
  text-transform: uppercase;
  color: #8d8d8d;
  line-height: 13px;
  margin-bottom: 19px;
}
.priceList p.count {
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  min-width: 21px;
  line-height: 21px;
  border: 1px solid #000000;
}

.priceList p.product,
.priceList p.duplicate {
  font-size: 16px;
  max-width: calc(100vw - 100px);
  width: 100%;
  line-height: 18px;
  position: relative;
  top: 1px;
}

.payWarn .product {
  color: black;
}

.payWarn .combieControlFont {
  color: white;
}

.priceList p:last-child {
  font-size: 14px;
  line-height: 18px;
  font-weight: 200;
  margin-left: auto;
  max-width: 96px;
  text-align: right;
  position: relative;
  top: 2px;
}

/* --- */

.dashed {
  width: 100%;
  height: 1px;
  background-color: #a3a3a3;
  margin-top: 8px;
}

.vrd {
  width: 1px;
  height: 32px;
  background-color: #a3a3a3;
}

.comments {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
  text-align: center;
  font-weight: 300;
  margin-top: 16px;
  letter-spacing: 0;
  flex: 1;
}

.comments svg {
  vertical-align: middle;
  margin-right: 10px;
}

.pbDescription {
  margin-top: 16px;
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* or 123% */

  text-align: center;

  color: var(--fontColor);
}

.togglerContainer {
  margin-top: 24px;
}

.togglerControl {
  margin-top: 8px;
}

.togglerHeader {
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */

  display: flex;
  align-items: center;
  letter-spacing: -0.24px;

  color: #000000;

  opacity: 0.5;
}

.dishLabel {
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: var(--fontColor);
}

.dishLabelSeparate {
  composes: dishLabel;
  margin-bottom: 8px;
}

.optionContainer {

}

.optionLabel {
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 86% */
  color: var(--fontColor);
}

.share {
  font-size: 20px;
  line-height: 25px;
  background-color: #ffffff;
  color: var(--shoppingcart-pay-color, #000000);
  border: 0.3px solid rgb(0, 0, 0);
  border-radius: 6px;
  display: block;
  width: 100%;
  text-align: center;
  max-width: 290px;
  margin: 24px auto 24px auto;
  padding: 12px 0 12px 0;
  // margin-bottom: 40px;
}

.rrowIcon {
  fill: var(--accentColor);
}

// .pay:hover {
//   background: #fbd121;
// }

.comments:hover {
  color: black;
}

a {
  text-decoration: none;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.vr {
  width: 1px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.2);
}

.flexD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 8px;
}

.hiddenSection {
  background: var(--accentColor);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;

  &_Bottom {
    border-radius: 0 0 4px 4px;
  }

  &_Top {
    border-radius: 4px 4px 0 0;
  }
}

.sectionHeader {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: var(--fontColor);
}

.uxissue {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
}

.flex1 {
  font-size: 12px;
  line-height: 24px;
}

.flex2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  flex: 1;
}

.flex1:last-child {
  text-align: right;
}

.hidden {
  display: none;
  // overflow: hidden;
  // max-height: 0px;
  // transition: max-height 0.5s ease;
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.exitIcon {
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/close_black.svg');
}

.dashed1 {
  border: 1px dashed #050505;
  width: 100%;
  margin: 12px 0px;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerPaid {
  transition: all 0.3s ease;
  align-items: center;
  display: flex;
  color: var(--fontCaptionColor);
}

.headerNotActive {
  color: var(--fontColor);
}

.headerActive {
  color: var(--fontCaptionColor);
}

.headerTakeoutReady {
  color: var(--successColor);
}

.headerTakeoutNotReady {
  color: var(--fontCaptionColor);
}

.headerTime {
}

.headerTimeleft {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  text-align: center;
  width: 100%;
}

.headerUnpaid {
  position: relative;
  display: flex;
  align-items: center;
}

.paidProduct {
  color: #8d8d8d;
}

.wrapperMargin:first-of-type {
  margin-top: 40px;
}

.wrapperMargin:last-of-type {
  margin-bottom: 40px;
}

.contextedWrapperPaid {
  min-width: 100%;
  min-height: 40px;
  padding: 12px 48px 12px 8px;
  border-radius: 4px;
}

ul {
}

// ul .wrapperMargin:nth-child(1) {
//   margin: 40px 0px 0px 0px;
// }

// ul .wrapperMargin:last-child {
//   margin: 0px 0px 40px 0px;
// }

// ul .wrapperMargin {
//   margin: 0px;
// }

.contextedWrapper {
  min-height: 40px;
  border-radius: 4px;
  z-index: 2;
  position: relative;
}

.orderWrapper {
  position: relative;

  &_Warn {
    .iconWrap {
      color: #fff;
      background-color: rgba(255,255,255,0.2);
    }

    .combieControlFont {
      color: #fff;
    }

    .hiddenSection {
      background-color: var(--warningColor);
    }
  }
}

.contextedPrice {
  min-width: 40px;
  display: flex;
  align-self: stretch;
  position: relative;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);

  // .combieText {
  //   font-weight: 600;
  // }
}

.uncontextedPrice {
  height: 100%;
  min-width: 40px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  path {
    fill: var(--fontColor);
  }
}

.orderItem {
  background-color: #ffffff;
  border: 0.5px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 3;

  &_Contexted {
    z-index: 1;
    border-radius: 0;
    border-color: transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -2px 4px rgba(0, 0, 0, 0.1);

    & + .orderItem_Paid {
      border-radius: 4px 4px 0 0;
    }
  }

}

.uncontextBlack {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
}

.unContexted {
  padding: 0px;
}

.contextedDish {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 8px;
  flex: 1;
}

.contexted .paidProduct {
  color: #000000;
}
.contexted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.contextedItem {
  width: 100%;
  position: relative;
  background: #ffffff;
  padding: 10px 8px 10px 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.1);
}

.clearfixHack {
  position: static;
}

.mw30 {
  min-width: 30%;
}

.tableLayout {
  display: flex;
  align-items: center;
}

.tableLayout svg path {
  fill: var(--shoppingCartEmptyColor);
}

.tableHash {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  margin-left: 6px;
}

.tableNum {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

.payWarn {
  background-color: var(--warningColor);
  color: white;
}

.payWarn3 {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(109, 109, 109, 0.5);
}

.warnText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.warnOrder {
  color: #9e9e9e;
}

.warnOrder2 {
  border: 0.5px solid var(--warningColor);
  border-radius: 4px;
  transition: 0.2s;

  &.orderItem_Contexted {
    border-radius: 0;
  }
}

.lds-ring {
  display: inline-block;
  position: absolute;
  right: 24px;
  width: 24px;
  height: 24px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 6px;
  border: 6px solid #9e9e9e;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #9e9e9e transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.doneProduct {
  display: none;
}

.headerCenter {
  position: absolute;
  left: 0px;

  padding: 4px 8px;
  border: 1px solid #ffcc3a;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerCenter2 {
  // border: 1px solid #ffcc3a;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerCenter_dis {
  position: absolute;
  left: 0px;

  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #858585;
}

.headerRight {
}

.headerRight33 {
  display: flex;
  align-items: center;
}

.headerRightItem {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

.paymentDiv {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.menuClosed {
  background-color: #dadada;
  color: var(--warningColor);
  font-weight: bold;
}

.paymentBlock {
  border-top: 0.5px solid var(--fontCaptionColor);
  min-height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
}

.pbElem {
  display: flex;
  flex: 131;
  width: 100%;
}

.pbElem2 {
  justify-content: space-between;
  flex: 244;
}

.pbIcon {
  width: 16px;
  margin-right: 12px;
  height: 16px;
  color: var(--fontCaptionColor);
}

.pbLabel {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* or 123% */
  display: flex;
  align-items: center;
  letter-spacing: -0.24px;
  color: var(--fontCaptionColor);
}

.pbText {
  max-width: calc(65vw - 52px);
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: var(--fontColor);
}

.pbText span {
  color: var(--fontColor);
}

.invalidLabel,
.invalidLabel span,
.invalidLabel .timedInput {
  color: var(--warningColor) !important;
}

.pbTextarea {
  box-shadow: none;
  border-top: none;
}

.pbTextarea textarea {
  margin-top: 24px;

  width: 100%;
  height: 56px;
  padding: 8px;
  background: transparent;
  border: 1px solid var(--fontCaptionColor);
  border-radius: 4px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 132% */

  color: var(--fontColor);
}

.pbTextarea textarea::placeholder {
  color: var(--fontCaptionColor);
}

.pbVr {
  margin: 0px 8px;
  width: 1px;
  height: 16px;
  border-left: 0.5px solid var(--fontCaptionColor);
}

.headerDate {
  width: 100%;
  margin: 12px 0px;
  min-height: 24px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  color: #000000;
}

.paidSection {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  color: rgba(0, 0, 0, 0.3);
}

.paidSectionLight {
  padding-top: 16px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  color: rgba(0, 0, 0, 0.3);
}

// .paidSection {
//   box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.1);
// }

.unpaidSection {
  padding-top: 24px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.detailed {
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 0px 0.5px #c5c5c5;
  // box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.1);
  // border: 1px solid #c5c5c5;
}

// .paidSection {
//   background: rgba(0, 0, 0, 0.03);
// }

.payWrapper {
}

.smallText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  /* or 10px */

  color: var(--fontCaptionColor);
  max-width: 36px;
  margin-left: 4px;
}

.segmentGroup {
  height: 32px;
  display: flex;

  border: 1px solid #f9d953;
  background: #f9d953;
  border-radius: 100px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  text-align: center;
  letter-spacing: -0.24px;

  color: #000000;
}

.segmentGroup div {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeSegmentLeft {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.activeSegmentCenter {
  border-radius: 0px;
}

.activeSegmentRight {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.activeSegment {
  background: #ffffff;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  text-align: center;
  letter-spacing: -0.24px;

  color: #979797;
}

// #F4F4F4color: #D1D1D1;

.disableSegment {
  background: #f4f4f4;
  color: #d1d1d1;
}

.togoSelectWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktopDrawer {
}

.timeOrder {
  width: 100px;
  display: flex;
  align-items: center;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #686868;
  margin-left: 16px;
  border-radius: 32px;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.disTime {
  opacity: 0.5;
}
.enTime {
  display: flex;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 32px;
  opacity: 1;
}

.nowTime {
  border: 1px solid #f9d953;
}

.timedInput {
  // width: 52px;
  padding: 0px 4px 0px 0px;
  border: none;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
}

.iamherePopup {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: #f9d953;
  // border-radius: 16px;
}

.macroText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.iamhere {
  padding: 8px 0 8px 0;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background: var(--accentColor);
  color: var(--fontInvertedColor);
}

.unpay {
  background: #f4f4f4;
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  // box-sizing: border-box;
  border-radius: 6px;
  // background: #dadada;
  box-shadow: none;
  // // border: 0.3px solid rgba(0, 0, 0, 0.2);
  // color: #979797;
}

.commentCheck {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: var(--fontColor);
  margin-bottom: 16px;
}

.disabledPay {
  background-color: transparent;
  border-radius: 6px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0 8px 0;
  align-items: center;
  text-align: center;
  color: #6d6d6d;
  box-shadow: none;
}

.disUnpay {
  padding: 8px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dadada;
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #6d6d6d;
  box-shadow: none;
}

.unDisPay {
  padding: 8px 0 8px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #000000;
  border-radius: 6px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;

  box-shadow: none;
  // // border: 0.3px solid rgba(0, 0, 0, 0.2);
  // color: #979797;
}

.confirmNotify {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
  width: 100vw;
  height: calc(100vh);
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: #000;
  transition: all 2.5s ease-in-out;
}

.confirmNotifyDisable {
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: none;
  display: none;
}

.segBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  border: 1px solid var(--fontCaptionColor);
  padding: 4px;
  margin-bottom: 10px;
  position: relative;
  .seg {
    height: 32px;
    flex: 1;
    text-align: center;
    border-radius: 32px;
    color: var(--fontCaptionColor);
    font-size: 13px;
    line-height: 32px;
    font-weight: 500;
    position: relative;
    transition: color 0.3s ease-out;
  }

  .markerActive {
    $markerWidth: calc((100% - 4px - 4px) / #{var(--buttonsCount)});
    width: $markerWidth;
    height: 32px;
    border-radius: 16px;
    background: var(--accentColor);
    position: absolute;
    top: 3px;
    left: 4px;
    transition: all 0.3s ease-in-out;

    &.l {
      left: 4px;
    }
    &.c {
      left: calc(#{$markerWidth} + 4px);
    }
    &.r {
      left: calc(100% - #{$markerWidth} - 4px);
    }
  }
}

.recipientRadioGroup {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 14px;
}

.drawer.notOpen {
  background-color: var(--warningColor);
  color: #ffffff;
}

.segBtn .seg.segDisabled {
  color: #cccccc;
}

.segBtn .seg.segDisabled.segActive {
  color: var(--fontInvertedColor);
}

.confirmNotifyEnable {
  backdrop-filter: blur(15px);
  background-color: rgba(162, 162, 162, 0.4);
  display: flex;
}

.contextPaid {
  justify-content: flex-start;
}

.contextPaid .product {
  color: #000000;
}

.contextWrapperPaid {
  justify-content: flex-start;
}

.segBtn span {
  z-index: 5;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scFlex {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.anim {
  animation: 0.5s ease;
  animation-name: cssAnimation;
  -webkit-animation: 0.5s ease;
  -webkit-animation-name: cssAnimation;
}

@keyframes cssAnimation {
  from {
    transform: rotate(0deg) scale(1) skew(0deg);
  }
  50% {
    transform: rotate(0deg) scale(1.6) skew(0deg);
  }
  to {
    transform: rotate(0deg) scale(1) skew(0deg);
  }
}

@-webkit-keyframes cssAnimation {
  from {
    transform: rotate(0deg) scale(1) skew(0deg);
  }
  50% {
    transform: rotate(0deg) scale(1.6) skew(0deg);
  }
  to {
    transform: rotate(0deg) scale(1) skew(0deg);
  }
}

@media (min-width: 375px) {
}

@media (min-width: 1025px) {
  .confirmNotify {
    width: 404px;
    height: calc(86vh - 40px);
    position: absolute;
  }
  .desktopDrawer::-webkit-scrollbar {
    display: none;
  }
  .desktopDrawer {
    max-height: calc(75vh - 40px);
    overflow: auto;
  }
  .content {
    padding: 0;
  }
}

.leaveTipsButtonsWrapper {
  display: flex;
  justify-content: space-between;
}

.leaveTipsButton {
  color: var(--fontColor);
  border: none;
  width: calc(50% - 8px);
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: calc(#{$dp} / 2);
  background-color: transparent;
  white-space: nowrap;
  margin-top: 40px;

  &Active {
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: var(--accentColor);
    color: var(--fontInvertedColor);
  }
}

.orderStatusIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
