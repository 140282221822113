@import '../../styles/variables.scss';

.wrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
  background: #fff;
  /* min-height: 100%; */
  overflow-y: scroll;
  padding-bottom: 44px;
  /* font-family: 'Stratos'; */
}

.manual {
  margin: 16px 16px 0 16px;
}

.manual input {
  border: none;
  width: 100%;
  flex: 1;
  text-align: center;
}

.manual input:active {
  background-color: #fff;
  color: #000;
}

.manual input:focus {
  background-color: #fff;
  color: #000;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  text-align: center;
  width: 100%;
  padding: 0 10px;
  background: #ffffff;
  border: 1px solid #717171;
  border-radius: 5px;
  border-radius: 10px;
  height: 48px;
}

.formInput {
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 8px;
}

.formInput::placeholder {
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: initial;

  color: #8d8d8d;
}

.center {
  flex-direction: column;
  justify-content: center;
}

.center .title {
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  text-align: center;
  letter-spacing: 0.215em;
  text-transform: uppercase;
}

.center .subtitle {
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
}

.randomText {
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;

  margin-top: 24px;
}

@media (min-width: 1025px) {
  .wrap {
    position: absolute;
  }
}
