@import 'src/styles/variables';

.priceWrapper {
  position: relative;
}

.title__wrap1 {
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  z-index: 1;
}

.tabListWrapper {
  min-height: 32px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.dishTitleInnerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.askText {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: left;
  color: var(--fontInvertedColor);
}

.ratesWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.rateWrapper {
  transition: 0.2s transform ease;
  will-change: transform;
  &:first-child {
    margin-right: 77px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 40px;
    margin-bottom: 8px;
    filter: grayscale(100%);
    opacity: 0.5;
    transition: 0.2s transform ease;
  }

  .tipText {
    font-size: 16px;
    line-height: 24px;
    color: var(--fontCaptionColor);
  }
}

.activeTip {
  transform: scale(1.1);
  img {
    opacity: 1;
    filter: grayscale(0);
  }
  .tipText {
    color: var(--fontInvertedColor);
  }
}

.textarea {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  height: 100%;
  resize: none;
}

.tableFont2 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  color: #000000;
}

.checkControl {
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.checkControl svg rect {
  fill: var(--accentColor);
}

.checkLabel {
  margin-left: 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
}

.wrapper {
  padding: 24px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 48px);
  overflow: auto;
  background: var(--viewBg);
}

.submitButton {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;

  &:disabled {
    opacity: 0.5;
  }

  svg {
    path {
      fill: var(--fontColor);
    }
  }
}

.attachInput {
  display: none;
  // position: absolute;
  // opacity: 0;
  // width: 100%;
  // height: 100%;
  // max-width: 32px;
  // max-height: 32px;
}

.headerRight {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.fullArea {
  flex: 0 1 50%;
  min-height: 80px;
}

.labelsWrapper {
  * {
    position: relative;
  }
}

.pageLoader {
  --offset: 2300;
  --time: 1.5s;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--loaderBackground);

  svg {
    path {
      stroke-width: 24;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: var(--offset);
      stroke-dashoffset: 0;
      stroke: var(--fontColor);
      transform-origin: 50% 50%;
    }
  }

  &DashA {
    animation: dash var(--time) ease-in-out alternate infinite;
  }
  &DashB {
    animation: dash var(--time) ease-in-out alternate-reverse infinite;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.volumeWrapper {
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: var(--fontCaptionColor);
  span {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.radioInput {
  display: none;
}

.radio {
  margin-top: $dp;
  position: relative;
  padding-left: 33px;
  transition: color 0.2s;

  &:not(:first-of-type) {
    margin-left: 24px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 7px;
    border-radius: 50%;
    background: var(--fontInvertedColor);
    height: 8px;
    width: 8px;
    transition: all ease 0.2s;
    transform: scale(0);
  }
}

.radioLabel {
  color: var(--fontCaptionColor);
  display: flex;
}

.radio:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  box-shadow: 0 0 1px #8d8d8d;
  background: var(--accentColor);
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.radioDisabled:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  box-shadow: 0 0 1px #8d8d8d;
  background: #c4c4c4;
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.radioDisabledChecked:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 7px;
  border-radius: 50%;
  background: var(--fontInvertedColor);
  height: 8px;
  width: 8px;
  transition: all ease 0.9s;
}

.radioChecked {
  color: var(--fontColor);

  &:after {
    transform: scale(1);
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: var(--offset);
  }
  to {
    stroke-dashoffset: 0;
  }
}

.dishTitle {
  max-height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  line-height: 28px;
}

.particles {
  z-index: 201 !important;
  pointer-events: none !important;
}

.newActivityViewWrapper {
  position: fixed;
  bottom: 8px;
  left: 8px;
  width: calc(100% - 16px);
}

.filesListWrapper {
  display: flex;
  flex-direction: column;
}

.fileItemWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.fileItemIcon {
  margin-right: 8px;

  path {
    &:first-child {
      fill: var(--fontCaptionColor);
    }

    &:last-child {
      stroke: var(--fontCaptionColor);
    }
  }

  img {
    width: 40px;
    max-width: 40px;
    max-height: 40px;
    object-fit: fill;
  }
}

.fileProgressWrapper {
  background-color: rgb(240, 240, 240);
  width: calc(100% - 48px);
  height: 4px;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

.progressItem {
  will-change: transform;
  transition: 0.1s all ease;
  position: absolute;
  left: -100%;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--accentColor);
}

.fileProgressLoaded {
  background-color: transparent;
  height: auto;
  border-radius: 0;
  position: relative;
  color: var(--fontCaptionColor);
  width: calc(100% - 80px);

  .progressItem {
    display: none;
  }
}

.fileDelete {
  margin-left: 8px;
  width: 24px;
  height: 24px;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    border-radius: 2px;
    background-color: var(--fontColor);
    width: 12px;
    height: 2px;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.plusButton {
  min-width: 56px;
  min-height: 56px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.2);
  background-color: var(--accentColor);
  color: var(--fontInvertedColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
