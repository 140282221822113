.wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
  display: flex;
  justify-content: center;
  width: 36px;
  align-items: center;

  div {
    background-color: #000;
    height: 4px;
    width: 50%;
    transition: 0.3s transform linear;

    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  &Inverted {
    div {
      background-color: #fff;
    }
  }
}

.rotated {
  div {
    &:first-child {
      transform: rotate(15deg) translateX(1px);
    }
    &:last-child {
      transform: rotate(-15deg) translateX(-1px);
    }
  }
}

.rotatedBack {
  div {
    &:first-child {
      transform: rotate(-15deg) translateX(1px);
    }
    &:last-child {
      transform: rotate(15deg) translateX(-1px);
    }
  }
}