.outerWrapper {
  position: relative;
  width: 100vw;
  height: 100vw;

  @media (min-width: 1025px) {
    width: 396px;
    height: 396px;
  }

  &Horizontal {
    height: 100vh;
  }
}

.wrapper {
  top: 0;
  position: absolute;
  overflow: hidden;
  touch-action: none;
  width: 100vw;
  height: 100vw;

  div {
    display: flex;
  }
}

.carouselItemWrapper {
  img,
  video {
    width: 100vw;
    height: 100vw;
    object-fit: cover;
  }
}

.indicatorsWrapper {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  display: none;
  flex-direction: column;
  align-items: center;
  z-index: 4;

  &Horizontal {
    top: 16px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    flex-direction: row;
    justify-content: center;

    .indicator {
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }
}

.indicatorsVisible {
  display: flex;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid var(--fontColor);
  background-color: #fff;
  opacity: 0.5;
  transition: 0.4s transform ease;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &Active {
    transform: scale(1.5);
  }
}

.imagePlaceholderWrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  top: 100vw;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(20px);
  }

  img {
    height: 10000%;
    width: 100%;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
}

.indicatorsWrapperHorizontal {
  z-index: 1;
}
