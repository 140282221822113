$card-height-value: 170;
$card-height: $card-height-value + px;
$cattab-height-value: 108;
$cattab-height: $cattab-height-value + px;

:export {
  cardHeightValue: $card-height-value;
  catTabValue: $cattab-height-value;
}

.dishCard {
  margin: 0 auto;
  height: 100%;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  background: var(--viewBg);
  width: calc(100% - 16px);
  will-change: height, transform, position, width;
  position: relative;

  &Open {
    border-radius: 0;
  }

  &Dark {
    color: #fff;

    .dishCardContent {
      &::before {
        background-color: #fff;
      }
    }
  }

  &Light {
    color: #000;
    .dishCardContent {
      &::before {
        background-color: #000;
      }
    }
  }
}

.dishCardInitialAnimation {
  // animation: showAnimation 0.3s linear;
  // animation-fill-mode: forwards;
  // animation-iteration-count: 1;
  // // opacity: 0;
  // transform: scale(0.85) translateY(-10px);
}

.dishCardOuterWrapper {
  margin-bottom: 8px;
  position: relative;
  height: $card-height;
  width: 100%;
}

.header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  will-change: opacity, transform;

  &Hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }

  .newLike {
    padding: 8px;
  }
}

.tagsWrapper {
  margin-left: 8px;
  margin-top: 8px;
  display: flex;
  max-height: 16px;
}

.badge,
.fav {
  display: none;
  position: absolute;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  will-change: transform, height;
  height: 56px;
}

.contentDrag {
  position: absolute;
  top: 106px;
  left: 8px;
  z-index: 2;
  width: calc(100% - 16px);
  padding-bottom: 8px;
}

.contentHeaderOuterWrapper {
  overflow: hidden;
  max-height: 40px;
  min-height: 40px;
}

.contentHeader {
  display: grid;
  min-height: 40px;
  grid-template-columns: 24px 1fr 40px;
  column-gap: 8px;
  align-items: center;
  padding: 0 8px;
  will-change: transform, opacity, visibility;

  &Hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }

  &Disabled {
    .price,
    .unitsWrapper {
      opacity: 0.5;
    }
  }
}

.dishIcon {
  display: flex;
  align-items: center;
  fill: rgba(0, 0, 0, 0.5);
}

.title {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  max-height: 40px;
  height: fit-content;
  width: 100%;
}

.priceBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  opacity: 0.75;
  width: 100%;
  text-align: right;
}

.gallery {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.img {
  height: 170px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  will-change: height;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  align-items: flex-end;
  justify-content: center;
}

.price {
  white-space: nowrap;
  font-size: 17px;
  opacity: 0.75;
  font-weight: 500;
  line-height: 24px;
}

.unitsWrapper {
  white-space: nowrap;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  opacity: 0.75;

  span {
    &:last-child {
      margin-left: 2px;
    }
  }
}

.imagePlaceholderWrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(60px);
  }

  img {
    // background-image: url(../../assets/testImage.jpeg) !important;
    height: 5000%;
    width: 100%;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  &Bg {
    background-color: var(--viewBg);

    &::before {
      display: none;
    }
  }
}

.dishCountBadge {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--accentColor);
  width: 16px;
  height: 16px;
  color: var(--fontInvertedColor);
  font-size: 11px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1;

  &Expanded {
    animation: badge 0.3s linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}

.dishCardContent {
  position: relative;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // flex: 1;
  will-change: transform, width, left, height;

  &::-webkit-scrollbar {
    display: none;
  }
}

.dishContentHeader {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.categoryWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px;
  padding-bottom: 0;
  align-items: center;
}

.categoryNameWrapper {
  display: flex;
  align-items: center;
  position: relative;
  span {
    margin-left: 8px;
  }
}

.dishUnitsWrapper {
  span {
    &:last-child {
      margin-left: 2px;
    }
  }
}

.dishTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.dishTitleWrapper {
  margin-top: 8px;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.likeWrapper {
  margin-left: 24px;
}

.dishBadgesWrapper {
  padding: 8px 0;
  display: flex;
  width: calc(100% - 16px);
  justify-content: space-between;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.dishPrice {
  margin-left: 24px;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
}

.badges {
  display: flex;
}

.dishSizeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.dishSizeTitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.dishSizes {
  height: 40px;
  width: calc(100% - 2px);
  border-radius: 20px;
  border: 1px solid var(--fontCaptionColor);
  display: flex;
  justify-content: space-between;
  position: relative;
  box-sizing: content-box;

  span {
    flex: 1;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
}

.dishSizeIndicator {
  background-color: var(--accentColor);
  height: 32px;
  position: absolute;
  left: 4px;
  top: 4px;
  width: calc(50% - 4px);
  border-radius: 20px;
  z-index: -1;
  transition: 0.3s transform ease;
  will-change: transform;
}

.activeSizeIndicator {
  color: var(--fontInvertedColor);
}

.dishTabsWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
}

.dishTab {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  opacity: 0.75;
  line-height: 40px;
  font-size: 14px;
  text-transform: capitalize;
}

.activeTab {
  opacity: 1;
}

.dishTabContentWrapper {
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px;
  scroll-behavior: smooth;
  // transition: 0.3s padding linear;
  will-change: padding;
}

.dishTabIndicator {
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 33.3%;
  transition: 0.3s all ease;
  left: 0;
  background-color: var(--accentColor);
}

.dishTabsOuterWrapper {
  width: 100%;
  overflow: hidden;
  padding-bottom: 8px;
}

.dishSizeVolumeWrapper {
  font-size: 13px;
  font-weight: 400;
  opacity: 0.75;
}

.dishShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  height: 48px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  transform: translateY(-100%);
  will-change: transform, opacity, visibility;
  pointer-events: none;
}

.test {
  height: 600px;
}

.badgesDisabled {
  width: 100%;
}

.disabledDishBadge {
  width: 100%;
  line-height: 24px;
  text-align: center;
  background-color: var(--warningColor);
  border-radius: 4px !important;
}

.carouseImageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.dishContentHeaderOuterWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  &Sticky {
    position: absolute;
    padding: 0 16px;
    top: 0;
    left: 0;
    z-index: 3;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
    // animation: headerAppear 0.4s ease;
    // animation-iteration-count: 1;
    // animation-fill-mode: forwards;
    .dishBadgesWrapper {
      border-bottom: none;
    }
  }
}

.carouselDishImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.dishSizePriceDiff {
  padding-left: 8px;
  position: relative;
}

.newLikeExpanded {
  position: absolute;
  width: 32px;
  height: 32px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: var(--viewBgExtraLight);
  display: flex;
  align-items: center;
  justify-content: center;
  right: 8px;
  top: 8px;
  transform: translateY(calc(-50% - 8px));
  border-radius: 50%;
}

.dishNavBar {
  width: 100%;
  padding: 0 8px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navBarLike {
    right: 8px;
  }
  margin-bottom: -8px;
}

@keyframes showAnimation {
  // from {
  //   // opacity: 0;
  //   transform: scale(0.85) translateY(-10px);
  // }
  to {
    transform: scale(1) translateY(0);
    // opacity: 1;
  }
}

@keyframes badge {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(8px, 8px);
  }
}

@keyframes headerAppear {
  from {
    transform: translateY(-48px);
  }

  to {
    transform: translateY(0);
  }
}

.tagsWrapperOverride {
  position: initial;

  div {
    padding: 0 4px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10;
    margin-right: 2px;
    height: 16px;
  }
  div:first-of-type {
    border-radius: 2px;
  }
  div:last-of-type {
    border-radius: 2px;
  }
}
.darkTags {
  div {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.lightTags {
  div {
    color: #000;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.tagsWrapperLarge {
  width: 100%;
  div {
    height: 24px;
  }
}

// .tag {
//   padding: 0 4px;
//   text-transform: uppercase;
//   font-weight: 500;
//   font-size: 10px;
//   line-height: 16px;
//   display: inline-block;
//   font-size: 10;
//   color: white;
//   margin-right: 2px;
//   background: rgba(0, 0, 0, 0.5);
// }

.carouseImagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--viewBgLighten);
  * {
    opacity: 0.5;
  }
}
