.wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 24px;
}

.tabName {
  position: relative;
  border-bottom: 1px solid var(--fontColor);

  span {
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    font-weight: 600;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(calc(-100% - 4px));
  }
}

.categoryItem {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px;
}

.categoryIcon {
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dishes {
  display: flex;
  flex-wrap: wrap;
}

.categoryTitle {
  padding: 0 4px;
  position: relative;
  margin-top: 8px;
  width: 100%;
  white-space: normal;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &Big {
    margin-top: 0;
    padding: 4px;
  }
}

.marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--accentColor);
  border-radius: 16px;
  z-index: -1;
}

.labelMarker {
  composes: marker;
}
