@import 'src/styles/variables';

.poi_selected {
  width: 300px;
}

.poi_default {
  width: 200px;
}

.poi_disabled {
  width: 100px;
}

.userPosition {
  z-index: 6;
}

.wrapper {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 201;
}

.react-tabs__list {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: flex-end;
  padding: $dp * 1.5 $dp * 1.5 0px $dp * 1.5;
  color: #000000;
}

.react-tabs__title {
  margin: 0px 8px;
  padding: 8px 16px;
  font-size: 0.875em;
  color: var(--fontColor);
  opacity: 0.5;
}

.react-tabs__list .react-tabs__tab--selected {
  opacity: 1;
  border-bottom: 2px solid var(--accentColor, #ffcc3a);
}

.react-tabs__tab--selected {
  opacity: 1;
  border-bottom: 2px solid var(--accentColor, #ffcc3a);
}

.emoji {
  width: 32px;
  height: 32px;
  font-size: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.icoMap {
  background-image: url('../../assets/ico-map-40.svg');
  width: 44.33px;
  height: 29.05px;
}

.icoPhone {
  background-image: url('../../assets/emoji/telephone-receiver_1f4de.png');
}

.list {
  background: var(--viewBg);
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.listStart {
  margin-top: 56px;
}

.venueSubname {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontColor);
  margin: 4px 0px;
}

.actionBlock {
  max-width: calc(100% - 32px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.venueBlock {
  min-width: 64px;
  width: calc(25% - 36px);
  height: 64px;

  background: #f4f4f4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0px 8px 16px 8px;
}

.listHeaderNotFar {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;

  color: var(--fontColor);

  opacity: 0.5;
  margin-bottom: 8px;
}

.listView {
  padding: 0px 16px;
}

.listCard {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 8px;
  min-height: 56px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  color: var(--fontColor);
}

.listCardSelected {
  color: var(--fontInvertedColor);
  background-color: var(--accentColor);
}

.reactTabs {
  color: var(--fontColor);
}

.listMeterFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  text-align: center;
}

.listLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin: 0px 20px;
}

.listCardHeaderFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 8px;
  /* identical to box height, or 107% */
}

.listCardBodyFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */
}

/* Old styles --------------------- */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 50;
  box-sizing: border-box;
}

.map {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.title {
  text-transform: uppercase;
  color: var(--fontColor);
}

.header {
  align-items: flex-start;
  display: flex;
}

.addres {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.hours {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: var(--fontColor);
  display: flex;
  &.out {
    color: red;
  }
}

.action {
  display: flex;
}

.mapWrapper {
  z-index: 2;
  width: 100%;
  padding: $dp $dp $dp * 0.5 $dp;
}

.mapRow {
  margin-top: 1rem;
}

.poi {
  width: 36px;
  height: 47px;
  background-size: 36px 47px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--poi-def-icon, url('../../assets/icons/poi.svg'));
}

.poiSelected {
  width: 62px;
  height: 88px;
  background-size: 62px 88px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--poi-sel-icon, url('../../assets/icons/poi-selected.svg'));
}

.poiDisabled {
  width: 36px;
  height: 47px;
  background-size: 36px 47px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--poi-dis-icon, url('../../assets/icons/poi_disabled.svg'));
}

.mapText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--fontColor);
}

.brandingAM {
  z-index: 1;
  position: absolute;
  top: 0px;
  background-repeat: repeat-x;
  background-image: var(--viewBg);
  background-size: 160px;
  opacity: 0.2;
  width: 100%;
  height: 88px;
}

.brandMap {
  background: var(--viewBg);
}

.superHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1025px) {
  .wrapper {
    position: absolute;
  }
  .navbar {
    position: absolute;
  }
  .map {
    width: 396px;
    height: calc(86vh);
  }
}
