@import 'src/styles/variables.scss';

.confirmationUi {
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationBox {
  margin: 0px 24px;
  max-width: 300px;
  width: 100%;
  min-height: 288px;

  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--accentColor);
  border-radius: var(--borderRadius);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.confirmationBottom {
  width: 100%;
}

.vr {
  min-width: 1px;
  background-color: #b4b4b4;
  height: 56px;
}

.confirmationBox1 {
  margin: 0px 16px 0px 16px;

  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--accentColor);
  border-radius: var(--borderRadius);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmationIcon {
  color: var(--fontInvertedColor);
}

.confirmationBoxHeader {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  text-align: center;

  color: var(--fontInvertedColor);
}

.confirmationBoxButtonWrapperInrapper {
  display: flex;
  width: 100%;
}

.confirmationBoxButtonInrapper {
  flex: 1;
  width: 100%;
  border-top: 1px solid #b4b4b4;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationBoxButtonInrapper:first-child {
  border-right: 1px solid #b4b4b4;
}

.confirmationBoxButton {
  border-top: 1px solid #b4b4b4;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--fontInvertedColor);
}

.tableFont {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-align: right;
  text-transform: capitalize;

  color: #ffffff;
  margin-bottom: 4px;
}

.tableFont1 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 45px;
  text-transform: capitalize;

  color: var(--fontInvertedColor);
}

.tableFont2 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;

  color: var(--fontInvertedColor);
}

.tableFont3_1 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  color: var(--fontInvertedColor);
}

.tableFont3 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 68px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;

  color: var(--fontInvertedColor);
}

.tableFont4 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--fontInvertedColor);
}

.codeText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: var(--fontInvertedColor);

  text-align: left;
}
