.wrapper {
  position: fixed;
  z-index: 5;
  background-color: var(--viewBg);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateY(calc(-100% + 220px));
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  animation: appear 0.5s;
  animation-iteration-count: 1;
  will-change: transform;
  animation-fill-mode: forwards;

  &Search {
    height: fit-content;
    z-index: 2;
  }

  &Closing {
    animation: close 0.5s;
    will-change: transform;
  }
}

.categoriesWrapper {
  max-height: calc(100% - 48px);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.headerScrolled {
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
}

@keyframes appear {
  to {
    transform: translateY(0);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

@keyframes close {
  from {
    transform: translateY(0);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  to {
    transform: translateY(calc(-100% + 220px));
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}
