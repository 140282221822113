.wrapper {
  width: 30px;
  height: 30px;
  border: 2px solid transparent;
  border-top: 2px solid var(--fontInvertedColor);
  border-radius: 50%;
  animation: load 1s linear infinite;

  &Sm {
    width: 18px;
    height: 18px;
    border: 1px solid transparent;
    border-top: 1px solid var(--fontInvertedColor);
  }
}

@keyframes load {
  to {
    transform: rotate(360deg);
  }
}
