@import 'src/styles/variables';

.face1 {
  background-image: url('../../assets/emoji/a1.png');
}

.face2 {
  background-image: url('../../assets/emoji/a3.png');
}

.face3 {
  background-image: url('../../assets/emoji/a4.png');
}

.face4 {
  background-image: url('../../assets/emoji/a5.png');
}

.face5 {
  background-image: url('../../assets/emoji/a6.png');
}

.face1,
.face2,
.face3,
.face4,
.face5 {
  mix-blend-mode: luminosity;
  opacity: 0.5;
  transition: all 0.4s ease;
  /* width: 40px;
  height: 40px; */
  /* background-size: 40px; */
  background-position: center;
  background-size: contain;
}

.confirmation {
  position: fixed;
  top: 0px;
  z-index: 200;
  width: 100vw;
  height: calc(100vh);
  background: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: #000;
}

.title {
  margin-top: 12px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.closeIcon {
  position: absolute;
  left: 10px;

  width: 36px;
  height: 48px;
  background-size: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/back.svg');
}
.settingsWrapper {
  position: absolute;
  bottom: 0px;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsImg {
  width: calc(100vh - 40px);
  height: 170px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--brandingBackgroundImage);
}

.textarea {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  height: 100%;
  resize: none;
}

.tableFont2 {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  color: #000000;
}

.checkControl {
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.checkControl svg rect {
  fill: var(--accentColor);
}

.check {
  background: rgba(0, 0, 0, 0.54);
  mix-blend-mode: normal;
}

.checkLabel {
  margin-left: 8px;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
}

.wrapper {
  padding: 24px;
  position: fixed;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 48px);
  background: var(--viewBg);
}

.list {
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(100vw - 80px);
  background-color: #ffffff;
  background: #ffffff;
  border-radius: 6px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  color: #455a64;
}

.item svg:first-of-type {
  margin-left: 32px;
  position: absolute;
}

.telegramText {
  margin-left: 12px;
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}

.item:nth-child(1) {
  margin-top: 18px;
}

.paymentText {
  width: calc(100vw - 80px);
  text-align: center;
}

.paymentWrapper {
  margin-top: 32px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faces {
  height: 104px;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  margin-bottom: 54px;
}

.face {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.face div:nth-child(1) {
  width: 40px;
  height: 40px;
}

.faceText {
  position: absolute;

  top: 64px;

  text-align: center;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #000000;
}

.face .faceText {
  display: none;
}

.selectedFace .faceText {
  display: flex;
}

.selectedFace div:nth-child(1) {
  width: 56px;
  height: 56px;
  opacity: 1;
  mix-blend-mode: normal;
  /* font-size: 44px; */
}

.commentUnButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: gray;
  border-radius: 6px;

  width: 93px;
  height: 32px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: (--fontColor);
}

.commentButon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--accentColor);
  border-radius: 6px;

  width: 93px;
  height: 32px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--fontColor);
}

.newBodytext {
  text-align: left;
  padding: 0px 24px 0px 24px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
}

.bodyText {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-size: 16px;
  /* margin-left: 16px; */
  line-height: 32px;
  /* or 178% */

  display: flex;
  align-items: center;

  color: var(--fontColor);
}

.someGuards {
  padding: 8px 16px;
}

@media (min-width: 1025px) {
  .textarea {
    width: 360px;
  }
}

// new styles

.ratesWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.askText {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: left;
  color: var(--fontInvertedColor);
}

.rateWrapper {
  transition: 0.2s transform ease;
  will-change: transform;
  &:first-child {
    margin-right: 77px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 40px;
    margin-bottom: 8px;
    filter: grayscale(100%);
    opacity: 0.5;
    transition: 0.2s transform ease;
  }

  .tipText {
    font-size: 16px;
    line-height: 24px;
    color: var(--fontCaptionColor);
  }
}

.activeTip {
  transform: scale(1.1);
  img {
    opacity: 1;
    filter: grayscale(0);
  }
  .tipText {
    color: var(--fontInvertedColor);
  }
}

.tipsWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 0 auto;
  position: absolute;
  top: calc(50% - 24px);
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  flex-direction: column;
}

.tipsSubmitButton {
  width: 100%;
  max-width: 228px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  background: transparent;
  height: 48px;
  background: var(--moneyColor);
  color: #fff;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 6px;
  border: none;
  transition: 0.2s all ease;

  &:disabled {
    color: #fff;
    opacity: 0.5;
    background: var(--fontCaptionColor);
  }
}

.rateSubmitButton {
  background: var(--accentColor);
  color: var(--fontInvertedColor);
  font-size: 16px;
  line-height: 24px;
  padding: 4px 8px;
  border-radius: calc(#{$dp} / 2);
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  &:disabled {
    color: #fff;
    opacity: 0.5;
    background: var(--fontCaptionColor);
  }
}

.liqpayDataWrapper {
  padding: 16px;
  padding-bottom: 24px;
  background: #fff;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    96.875% calc(100% - 10px),
    93.75% 100%,
    90.625% calc(100% - 10px),
    87.5% 100%,
    84.375% calc(100% - 10px),
    81.125% 100%,
    78.125% calc(100% - 10px),
    75% 100%,
    71.875% calc(100% - 10px),
    68.75% 100%,
    65.625% calc(100% - 10px),
    62.5% 100%,
    59.375% calc(100% - 10px),
    56.25% 100%,
    53.125% calc(100% - 10px),
    50% 100%,
    46.875% calc(100% - 10px),
    43.75% 100%,
    40.625% calc(100% - 10px),
    37.5% 100%,
    34.375% calc(100% - 10px),
    31.125% 100%,
    28.125% calc(100% - 10px),
    25% 100%,
    21.875% calc(100% - 10px),
    18.75% 100%,
    15.625% calc(100% - 10px),
    12.5% 100%,
    9.375% calc(100% - 10px),
    6.25% 100%,
    3.125% calc(100% - 10px),
    0 100%
  );
  margin: 24px 16px 40px 16px;
}

.checkRow {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
    color: var(--fontCaptionColor);
  }
}

.liqpayDataHeader,
.liqpayDataVenue,
.liqpayDataAmount {
  display: flex;
  justify-content: space-between;
}

.liqpayDataVenue {
  padding-bottom: 16px;
  // padding-top: 24px;
  border-bottom: 1px solid var(--fontCaptionColor);

  span {
    margin-left: 8px;
    font-size: 13px;
    line-height: 16px;
  }
}

.liqpayDataAmount {
  padding-top: 16px;
  span {
    font-size: 16px;
    line-height: 24px;
    &:first-child {
      font-weight: 500;
    }
  }
}

.areaShrink {
  max-height: 88px;
  margin-bottom: 40px;
}

.fullArea {
  flex: 0 1 50%;
}

.buttonsWrapper {
  position: relative;
  width: 332px;
  height: 300px;
  transition: 0.5s transform ease;
}

.heartText {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  text-replace: auto;
  // transition: 0.5s all ease;
  left: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 36px;

  span {
    position: absolute;
    right: -10px;
    top: 0;
    opacity: 0.75;
    font-size: 18px;
  }
}

.otherAmountText {
  font-size: 18px;
}

.otherAmountInput {
  border: none;
  background: transparent;
  outline: none;
  left: auto;
  width: 100%;
  text-align: center;
  &::placeholder {
    color: #fff;
    font-size: 36px;
  }
  transform: translateY(-50%) rotate(-180deg);

  &Active {
    transform: translateY(-50%) rotate(0);
  }
}

.modalWrapper {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  display: flex;
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .heartWrapper {
    position: relative;
    width: 80vw;
  }

  p {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  button {
    color: var(--accentColor);
    border: none;
    background: transparent;
    padding: 16px;
  }
}

.heartWrapper {
  transition: 0.5s transform ease;
}
