.orderCardHeader {
  display: flex;
  min-height: 56px;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
}

.miniIconsWrapper {
  display: flex;
}

.orderCardWrapper {
  overflow: hidden;
  width: calc(100% - 16px);
  background: var(--viewBgLighten);
  margin-bottom: 8px;
  border-radius: calc(var(--borderRadius) / 2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  min-height: 56px;
  color: var(--fontColor);
}

.iconWrapper {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.orderCardContentWrapper {
  height: 0;
  transition: 0.3s all ease;
  will-change: height;
}

.orderCardContentInnerWrapper {
  padding: 8px;

  button {
    margin: 0 auto;
    background-color: transparent;
    border: none;
    color: var(--fontColor);
    font-size: 16px;
    line-height: 19px;
    display: block;
    padding: 8px;
  }
}

.orderContentDishWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.dishTitle {
  margin: 0 16px;
  font-size: 13px;
  line-height: 24px;
  span {
    text-transform: lowercase;
  }
}

.price {
  white-space: nowrap;
  font-family: var(--body-fontfamily, Stratos);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: right;
  letter-spacing: -0.05em;

  color: var(--fontColor);

  &Small {
    font-size: 14px;
    line-height: 24px;
  }
}

.dishCount {
  font-size: 14px;
  line-height: 24px;
}

.dishesListClosed {
  opacity: 0;
}
