@import "src/styles/variables";

.list {
  overflow-x: auto;
  display: flex;
  padding: 0 $dp;
  align-items: center;
  margin: 0 -#{$dp};
  -webkit-overflow-scrolling: touch;
  touch-action: pan-x;

  &::-webkit-scrollbar {
    display: none;
  }
}

.listItem {
  margin-right: 8px;
  padding: 8px 0;
  &:last-of-type {
    margin-right: 0;
    padding-right: $dp;
  }
}

.item {
  min-width: 143px;
  padding: 4px;
  min-height: 180px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--viewBg);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: relative;
}

.itemFavIcon {
  position: absolute;
  right: 8px;
  top: 8px;
}

.itemCounter {
  position: absolute;
  left: 4px;
  top: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;
  background-color: var(--accentColor);
  color: var(--fontInvertedColor);
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
}

.itemDescription {
  margin-top: auto;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  padding: 8px;
  position: relative;
}

.itemPrice {
  padding: 4px 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: var(--fontInvertedColor);
  opacity: 0.75;
}

.itemTitle {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  min-height: 32px;
  color: var(--fontInvertedColor);
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.itemAddButton {
  position: absolute;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  right: 8px;
  top: 0;
  transform: translateY(-50%);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.14), 0 0.5px 10px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.2);
}

.shoppingCart {
  padding: 8px $dp;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
}

.shoppingCartTitle {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--fontColor);
}