.root {
  display: flex;
  align-items: center;
  line-height: 24px;
  //margin-right: 10px;
  background: transparent;
  color: var(--fontColor);
  font-size: 12px;
  text-transform: uppercase;
  //margin-bottom: 10px;
  border: 1px solid var(--fontColor);
  border-radius: 27px;
  padding: 4px 12px;
}

.active {
  background: var(--viewBg);
  border-color: var(--fontColor);
  color: var(--fontInvertedColor);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 24px;
}

.plus {
  font-size: 18px;
}

.check {
  display: block;
  width: 5px;
  height: 10px;
  border-radius: 1px;
  border: solid var(--fontInvertedColor);
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}