@import 'src/styles/variables.scss';

.uxissue {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;

  option {
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.paymentBlock {
  position: relative;
  border-top: 0.5px solid var(--fontCaptionColor);
  min-height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
}
.pbVr {
  margin: 0px 8px;
  width: 1px;
  height: 16px;
  border-left: 0.5px solid var(--fontCaptionColor);
}

.pbElem {
  display: flex;
  flex: 131;
  width: 100%;
}

.pbElem2 {
  justify-content: space-between;
  flex: 244;
}

.pbText {
  max-width: calc(65vw - 52px);
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: var(--fontColor);
}

.pbText span {
  color: var(--fontColor);
}

.invalidLabel,
.invalidLabel span,
.invalidLabel .timedInput {
  color: var(--warningColor) !important;
}

.pbTextarea {
  box-shadow: none;
}

.pbTextarea textarea {
  margin-top: 24px;

  width: 100%;
  height: 56px;
  padding: 8px;
  background: transparent;
  border: 1px solid var(--fontCaptionColor);
  border-radius: 4px;

  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 132% */

  color: var(--fontColor);
}

.pbTextarea textarea::placeholder {
  color: var(--fontColor);
}

.timedInput {
  // width: 52px;
  padding: 0px 4px 0px 0px;
  border: none;
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  line-height: 18px;
  /* identical to box height */
}

.rowIcon {
  fill: var(--accentColor);
}

.pbIcon {
  width: 16px;
  margin-right: 12px;
  height: 16px;
  color: var(--fontCaptionColor);
}

.pbLabel {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.24px;
  color: var(--fontCaptionColor);
}

.deliveryOwnLabel {
  color: var(--warningColor) !important;
}
