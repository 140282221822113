@import 'src/styles/variables.scss';

.drawer {
  width: 100%;
  border-radius: 8px;
  background: var(--shoppingCartEmptyBgColor);
  color: var(--shoppingCartEmptyColor);
  will-change: background-color;
  transition: 0.5s background-color ease;
}

.drawer.closed__empty,
.drawer.closed__full {
  bottom: -50px;
  height: 56px;
}

.drawer.open__empty,
.drawer.open__full {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.drawer.closed__empty {
  background: var(--shoppingCartEmptyBgColor);

  .title {
    color: var(--shoppingCartEmptyColor);
  }
}

.drawer.closed__full:not(.drawer.notOpen) {
  background: var(--shoppingCartBgColor);

  .title {
    color: #fff;
  }
}

.drawer.open__full {
  top: 70px;
  background: var(--viewBg);
  color: var(--fontColor);

  .title {
    color: inherit;
  }
}

.drawer.closed {
  height: 56px;

  &Disabled {
    .title {
      color: var(--fontColor);
    }
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    color: var(--fontColor);
  }
}

.drawer.open__empty {
  height: 301px;
  margin-top: 0;
  background-image: var(--shoppingCartEmptyBg);
  background-repeat: no-repeat;
  color: var(--shoppingCartEmptyColor);
  background-size: auto;
  background-position: right center;
  background-color: var(--shoppingCartEmptyBgColor);
}

.drawer {
  &.open__empty,
  &.closed {
    .holidayDecorationTop {
      &_newYear {
        position: absolute;
        top: -7px;
        right: -9px;
        background-image: url('../../assets/holidays/newYear/winter_cart_decoration_top.png');
        width: 67px;
        height: 58px;
        z-index: 3;
      }
    }
  }
}

.drawer_nm.open__empty {
  background-image: url('../../assets/brandSign.png');
}

.drawer_hlbn.open__empty {
  background-image: url('../../assets/man-white.svg');
}

.icoSleep {
  width: 26px;
  height: 26px;
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/ico-sleep@2x.png');
}

.content {
  // position: relative;
  // padding: 0rem 0 100vh 0;
  height: 100%;
  position: relative;
}

.contentInnerWrapper {
  padding: 0 16px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.175);

  &::after {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 16px;
    z-index: 1;
  }
}

.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
}

/* --------- */

ul,
li,
p {
  margin: 0;
  padding: 0;
}

.title {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.optionContainer {
}

a {
  text-decoration: none;
}

.clearfixHack {
  position: static;
}

.tableLayout {
  display: flex;
  align-items: center;
}

.tableNum {
  font-family: $bodyFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.drawer.notOpen {
  background-color: var(--warningColor);
  color: #ffffff;
}

@media (min-width: 1025px) {
  .confirmNotify {
    width: 404px;
    height: calc(86vh - 40px);
    position: absolute;
  }
  .desktopDrawer::-webkit-scrollbar {
    display: none;
  }
  .desktopDrawer {
    max-height: calc(75vh - 40px);
    overflow: auto;
  }
  .content {
    padding: 0;
  }
}

.activityView {
  background: transparent;

  &Active {
    background: var(--viewBg);
  }
}

.plusButton {
  position: absolute;
  left: 50%;
  top: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: var(--accentColor);
  color: var(--fontInvertedColor);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.5s all ease;
  border: none;
  will-change: transform, opacity;

  &:active {
    transform: translate(-50%, -50%) scale(0.9);
  }

  &Visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 1;
  }
}

.dishesIconsWrapper {
  display: flex;
  align-items: center;
}

.dishIconWrapper {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-right: 4px;
  }

  &:last-child {
    margin-right: 8px;
  }
}

.dishCount {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  background-color: var(--shoppingCartEmptyColor);
  color: var(--fontColor);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &Active {
    color: var(--fontInvertedColor);
    background-color: var(--accentColor);
  }
}

.paymentButtonClosed {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--accentColor);
  color: var(--fontColor);
  padding: 9px 14px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0px 8px;
  border: none;
  transform: translateY(calc(-100% + 9px)) scale(0.5);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.5s all ease;
  will-change: opacity, transform;

  &Visible {
    transform: translateY(calc(-100% + 9px)) scale(1);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.scanButton {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--shoppingCartEmptyColor);
  color: var(--shoppingCartBgColor);
  text-transform: uppercase;
  padding: 9px 14px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 8px 0px;
  border: none;
  transform: translateY(calc(-100% + 9px)) scale(0.5);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.5s all ease;
  will-change: opacity, transform;

  &Visible {
    transform: translateY(calc(-100% + 9px)) scale(1);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.drawer.closed__dark {
  background-color: #fff !important;

  .contentInnerWrapper {
    &::after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgb(255, 255, 255));
    }
  }

  .title {
    color: #000 !important;
  }

  .dishCount {
    color: #fff;
    background-color: #000;

    &Active {
      background-color: var(--accentColor);
    }
  }
}

.drawer.closed__light {
  background-color: #000 !important;

  .contentInnerWrapper {
    &::after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgb(0, 0, 0));
    }
  }
}

.labelWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-left: 40px;
}

.warnIconWrapper {
  margin-left: 8px;
}
